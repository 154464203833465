import React, { useState } from "react";
import { ChevronRight, ChevronDown, Link } from "heroicons-react";
import FileBase64 from "react-file-base64";
import ManageLinks from "../../components/ManageLinks";

const Header = ({
  view,
  setView,
  designInfo,
  setDesignInfo,
  setPopUp,
  setModal,
}) => {
  const [viewHeader, setViewHeader] = useState(false);
  const [logoName, setLogoName] = useState("");
  const [urlError, setUrlError] = useState(false);
  const urlRegex =
    /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+)(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/;
  const OnChangeUploadFile = async (base64) => {    
    if (
      base64.type === "image/png" ||
      base64.type === "image/jpg" ||
      base64.type === "image/jpeg" ||
      base64.type === "image/jfif"
    ) {
      setLogoName(base64.name);
      setDesignInfo({ ...designInfo, headerLogo: base64 });
    }    
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value && urlRegex.test(value)) {
      setUrlError(false);
    } else setUrlError(true);
    setDesignInfo({ ...designInfo, [name]: value });
  };

  return (
    <>
      {viewHeader ? (
        <>
          <div className="w-[85%] flex-shrink bg-[#F5F5F5] border-[2px] rounded-[8px] cflexss">
            <div
              className={`w-full flexbm px-[14px] py-[12px] rounded-t-[8px] cursor-pointer`}
              onClick={() => {
                setViewHeader(false);
              }}
            >
              <p>Header</p>
              <ChevronDown size="24px" />
            </div>
            <div className="w-full bg-white cflexss rounded-b-[8px] text-[#656667] pb-[10px] font-normal text-[14px]">
              <div className="w-full cflexss p-[16px] gap-[16px]">
                <div className="cflexss gap-[6px]">
                  <p className="font-semibold text-[#1F2023]">Logo</p>
                  <p>Upload company logo</p>
                </div>
                <div className="relative rounded-[8px] px-[16px] py-[8px] gap-[8px] flexmm text-[#1F2023] bg-[#E6E6E6] font-medium cursor-pointer">
                  <img src="/cld.svg" alt="uploadedImage" />
                  <p>Upload Image</p>
                  <div className="absolute top-2 left-[-100px] opacity-0">
                    <FileBase64
                      name="headerLogo"
                      defaultValue={designInfo["headerLogo"]}
                      multiple={false}
                      onDone={(base64) => {
                        OnChangeUploadFile(base64);
                      }}
                    />
                  </div>
                </div>
                {logoName && (
                  <p className="text-[12px] text-black">{logoName}</p>
                )}
              </div>

              {urlError && (
                <p className="px-[16px] text-[12px] text-red-500">
                  please provide a valid url
                </p>
              )}
              {/* <div className="w-full cflexss p-[16px] gap-[16px]">
                <div className="cflexss gap-[6px]">
                  <p className="font-semibold text-[#1F2023]">
                    Link to your homepage
                  </p>
                </div>
                <input
                  type="url"
                  className="w-full shadow-sm rounded-[6px] border-[1px] outline-none px-[12px] py-[8px]"
                  placeholder="e.g https://www.homepage.com"
                  name="headerHomePageLink"
                  value={designInfo["headerHomePageLink"]}
                  onChange={handleChange}
                />
              </div> */}

              <div className="w-full cflexss p-[16px] gap-[16px]">
                <div className="cflexss gap-[6px]">
                  <p className="font-semibold text-[#1F2023]">
                    Link to contact us page
                  </p>
                </div>
                <input
                  type="url"
                  className="w-full shadow-sm rounded-[6px] border-[1px] outline-none px-[12px] py-[8px]"
                  placeholder="e.g https://www.contact us.com"
                  name="headerContactPageLink"
                  value={designInfo["headerContactPageLink"]}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full cflexss p-[16px] pb-[40px] gap-[16px]">
                <div className="cflexss gap-[6px]">
                  <p className="font-semibold text-[#36383A]">Header link</p>
                  <p>Add and manage links in the header</p>
                </div>
                <div
                  className="rounded-[8px] px-[16px] py-[8px] gap-[8px] flexmm text-[#1F2023] bg-[#E6E6E6] font-medium cursor-pointer"
                  onClick={() => {                    
                    setModal(
                      <ManageLinks
                        setPopUp={setPopUp}
                        designInfo={designInfo}
                        setDesignInfo={setDesignInfo}
                      />
                    );
                    setPopUp(true);
                  }}
                >
                  <Link size="20px" />
                  <p>Manage links</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`w-[85%] flexbm flex-shrink ${
              view === "Header" && "bg-[#F5F5F5]"
            } px-[14px] py-[12px] rounded-[8px] cursor-pointer border-[2px]`}
            onClick={() => {
              setViewHeader(true);
              setView("Header");
            }}
          >
            <p>Header</p>
            <ChevronRight size="24px" />
          </div>
        </>
      )}
    </>
  );
};

export default Header;
