import React from "react";
import { PaystackButton } from "react-paystack";

const PaystackPayment = ({ amount, email, publicKey, currency }) => {
  console.log(currency)
  console.log(amount)
  const onSuccess = (reference) => {
    console.log(reference);
    alert("Payment Successful!");
  };

  const onClose = () => {
    alert("Payment closed");
  };

  const componentProps = {
    email,
    amount,
    publicKey,
    text: "Get Alps Pro",
    currency: currency,
    onSuccess,
    onClose,
  };

  return (
    <>
      <div className="w-full bg-[#2C2B2B] px-[17px] flexmm py-[8px] font-semibold rounded-[10px] text-[16px] text-white shadow-md">
        <PaystackButton {...componentProps} />
      </div>
    </>
  );
};

export default PaystackPayment;
