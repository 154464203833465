import React, {useEffect} from "react";
import Header from "./Header";
import HeroSection from "./HeroSection";
import Body from "./Body";
import Footer from "./Footer";

const LandingPage = () => {
  useEffect(()=>{
    let alpsToken = localStorage.getItem("alpsToken");
    if(alpsToken){
      window.location.href = "/collections";  
    }
  },[])
  return (  
    <>
      <Header />
      <HeroSection />
      <Body />
      <Footer />
    </>
  );
};

export default LandingPage;
