import React from "react";
import { Link } from "react-router-dom";

const TermsPrivacy = () => {
  return (
    <>
      <Link
        to="/privacy-policy"
        className="text-[14px] flexmm gap-[10px] font-inter"
      >
        <p>Terms of use</p> <p>|</p> <p>Privacy Policy</p>
      </Link>
    </>
  );
};

export default TermsPrivacy;
