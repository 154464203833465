import React, { useState } from "react";
import Button from "./Button";
import { X } from "heroicons-react";
import Loader from "./Loader/Loader";
import { notifyError } from "../services/toastify";
import { createGuideVideo, updateGuideVideo } from "../services/request";

const AddNewVideo = ({
  setIsOpenModal,
  playlistId,
  setRefresh,
  refresh,
  guide,
}) => {
  const youtubeRegex =
    /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})$/;
  const [videoDetails, setVideoDetails] = useState({
    link: `${guide ? guide.link : ""}`,
    name: `${guide ? guide.title : ""}`,
    summary: `${guide ? guide.summary : ""}`,
    playlist: playlistId,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setVideoDetails({ ...videoDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let alpsToken = localStorage.getItem("alpsToken");    
    if (youtubeRegex.test(videoDetails.link) && videoDetails.playlist) {
      setLoading(true);
      if (guide) {
        await updateGuideVideo(alpsToken, videoDetails, guide.id);
      } else {
        await createGuideVideo(alpsToken, videoDetails);
      }
      setRefresh(!refresh);
      setLoading(false);
      setIsOpenModal(false);
    } else if (videoDetails.link && !youtubeRegex.test(videoDetails.link)) {
      notifyError("Invalid youtube link");
    }
  };
  return (
    <>
      <div
        className="fixed z-[99] top-0 right-0 w-full h-[100vh] flexms py-[20px] bg-secondary3 font-inter"
        onClick={() => {
          setIsOpenModal(false);
        }}
      >
        <form
          className="flexss w-[530px] flex-shrink rounded-[6px] overflow-y-auto p-[20px] bg-white shadow-xl"
          onClick={(e) => {
            e.stopPropagation();
          }}
          onSubmit={handleSubmit}
        >
          <div className="cflexss w-full gap-[22px]">
            <div className="cflexss w-full gap-[32px]">
              <div className="w-full flexbm text-[18px] font-semibold text-buttonColor">
                <p>Add a new video</p>
                <X
                  size="20px"
                  color="#7C7D7E"
                  className="cursor-pointer"
                  onClick={() => {
                    setIsOpenModal(false);
                  }}
                />
              </div>
              <div className="w-full rounded-[10px] h-[210px] bg-[#D9D9D9]">
                {guide && (
                  <img
                    src={guide.thumbnail}
                    className="w-full h-full object-cover rounded-[10px] flexmm"
                    alt="guide_thumbnail"
                  />
                )}
              </div>
              <div className="cflexss w-full gap-[8px]">
                <input
                  type="url"
                  name="link"
                  value={videoDetails.link}
                  onChange={handleChange}
                  className="w-full rounded-[4px] outline-none p-[6px] border-[2px]"
                  placeholder="Link to video e.g. https://www.youtube.com/"
                />
                <input
                  type="text"
                  name="name"
                  value={videoDetails.name}
                  onChange={handleChange}
                  className="w-full rounded-[4px] outline-none p-[6px] border-[2px]"
                  placeholder="Title [Optional]"
                />
                <textarea
                  type="text"
                  name="summary"
                  value={videoDetails.summary}
                  onChange={handleChange}
                  className="w-full rounded-[4px] h-[100px] resize-none outline-none p-[6px] border-[2px]"
                  placeholder="Summary [Optional]"
                />
              </div>
            </div>

            <div className="w-full flexem">
              <Button type="submit" gap="10px" handleClick={() => {}}>
                {loading ? (
                  <>
                    <Loader />
                    {guide ? <p>Update video</p> : <p>Add video</p>}
                  </>
                ) : (
                  <>{guide ? <p>Update video</p> : <p>Add video</p>}</>
                )}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddNewVideo;
