import React, { useState, useEffect } from "react";
import LinkAutocomplete from "@editorjs/link-autocomplete";
import TextVariantTune from "@editorjs/text-variant-tune";
import Paragraph from "@editorjs/paragraph";
import EditorJS from "@editorjs/editorjs";
import ImageTool from "@editorjs/image";
import LinkTool from "@editorjs/link";
import Header from "@editorjs/header";
import RawTool from "@editorjs/raw";
import NestedList from "@editorjs/nested-list";
import Embed from "@editorjs/embed";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import CodeTool from "@editorjs/code";
import Table from "@editorjs/table";
import { uploadImage } from "../../services/request";
import { jsonToMarkdown, markdownToJson } from "./Converter";

const Editor = ({ articleDetails, setArticleDetails }) => {
  const api = "https://api.tryalps.com/api/v1";    
  let editor = null;

  useEffect(() => {
    setTimeout(() => {
      editor = new EditorJS({
        /**
         * Id of Element that should contain Editor instance
         */
        holder: "editorjs",
        tools: {
          header: {
            class: Header,
            // inlineToolbar: ["marker", "link", "bold", "italic"],
            config: {
              placeholder: "Header",
            },
            shortcut: "CMD+SHIFT+H",
            tunes: ["textVariant"],
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
            tunes: ["textVariant"],
          },
          textVariant: TextVariantTune,
          linkTool: {
            class: LinkTool,
            // config: {
            //   endpoint: "http://localhost:8008/fetchUrl", // Your backend endpoint for url data fetching,
            // },
          },
          link: {
            class: LinkAutocomplete,
            config: {
              endpoint: "http://localhost:3000/",
              queryParam: "search",
            },
          },
          raw: RawTool,
          // checklist: {
          //   class: Checklist,
          //   inlineToolbar: true,
          // },
          // list: {
          //   class: List,
          //   inlineToolbar: true,
          //   config: {
          //     defaultStyle: "unordered",
          //   },
          // },
          list: {
            class: NestedList,
            inlineToolbar: true,
            config: {
              defaultStyle: "unordered",
            },
          },
          embed: Embed,
          quote: {
            class: Quote,
            inlineToolbar: true,
            shortcut: "CMD+SHIFT+O",
            config: {
              quotePlaceholder: "Enter a quote",
              captionPlaceholder: "Quote's author",
            },
            tunes: ["textVariant"],
          },
          image: {
            class: ImageTool,
            config: {
              endpoints: {
                byFile: `${api}/article/upload`, // Your backend file uploader endpoint
                byUrl: "http://localhost:8008/fetchUrl", // Your endpoint that provides uploading by Url
              },
              field: "file", // Field name for the file
              additionalRequestHeaders: {
                Authorization: `Bearer ${localStorage.getItem("alpsToken")}`,
                "Content-Type": "multipart/form-data",
              },
              captionPlaceholder: "Caption",
              uploader: {
                uploadByFile(file) {
                  return uploadImage({ file });
                },
              },
              actions: [
                {
                  name: "new_button",
                  icon: "<svg>...</svg>",
                  title: "New Button",
                  toggle: true,
                  action: (name) => {
                    alert(`${name} button clicked`);
                  },
                },
              ],
            },
          },
          Marker: {
            class: Marker,
            shortcut: "CMD+SHIFT+M",
          },
          code: CodeTool,
          delimiter: Delimiter,
          inlineCode: InlineCode,
          table: Table,
          // myOwnParagraph: MyParagraph
        },
        onReady: () => {
          // editor.data = markdownToJson(articleDetails.articleBody);          
        },
        onChange: (api, event) => {    
          save();
        },
        data: markdownToJson(articleDetails.articleBody),
        dragDrop: true,
        placeholder: "Press / for command",
        style: {
          background: "#f3f3f3",
          width:"100%",
          nonce: "bg-red-800",
        },
      });
    }, 100);
  }, []);

  const save = async () => {
    editor &&
      (await editor
        .save()
        .then((outputData) => {          
          setArticleDetails({
            ...articleDetails,
            articleBody: jsonToMarkdown(outputData),
          });          
        })
       );
  };

  return (
    <>
      <div className="w-full cflexss gap-[20px]">
        <div id="editorjs" className="w-full flexss" />
      </div>
    </>
  );
};

export default Editor;
