import React, { useState, useEffect } from "react";
import ChangeCover from "../../components/ChangeCover";
import Editor from "./Editor";
import {
  createArticle,
  updateArticle,
  publishArticle,
  getUserProfile,
} from "../../services/request";
import Button from "../../components/Button";
import Loader2 from "../../components/Loader/Loader2";
import Loader from "../../components/Loader/Loader";
const AddContent = ({
  id,
  articleId,
  setArticleId,
  setContent,
  article,
  setArticle,
  setEdited,
}) => {
  const [articleDetails, setArticleDetails] = useState({
    title: `${article?.title || ""}`,
    file: `${article?.coverURL || ""}`,
    description: `${article?.description || ""}`,
    articleBody: `${article?.articleBody || ""}`,
  }); 
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [modal, setModal] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [published, setPublished] = useState(false);
  const [changing, setChanging] = useState(false);
  const [type, setType] = useState(`${article ? "update" : "create"}`);
  const [updatedAt, setUpdatedAt] = useState(`${article?.updatedAt || ""}`);
  const [loading, setLoading] = useState(false);
  const [pLoading, setPLoading] = useState(false);
  const [userProfile, setUserProfile] = useState("");

  function getTimeDifference(timestamp) {
    const currentTime = new Date();
    const difference = currentTime.getTime() - timestamp.getTime();

    // Convert milliseconds to seconds
    const seconds = Math.floor(difference / 1000);

    if (seconds < 60) {
      if (seconds > 0 && seconds < 60) {
        return seconds + " seconds ago";
      } else if (seconds === 0) {
        return " just now";
      }
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return minutes + ` ${minutes > 1 ? "minutes ago" : "minute ago"}`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return hours + ` ${hours > 1 ? "hours ago" : "hour ago"}`;
    }

    const days = Math.floor(hours / 24);
    if (days < 30) {
      return days + ` ${days > 1 ? "days ago" : "day ago"}`;
    }

    const months = Math.floor(days / 30);
    if (months < 12) {
      return months + ` ${months > 1 ? "months ago" : "month ago"}`;
    }

    const years = Math.floor(months / 12);
    return years + ` ${years > 1 ? "years ago" : "year ago"}`;
  }

  const [timeAgo, setTimeAgo] = useState("");

  // const addToContent = (newItem) => {
  //   setEditorContent((prevContent) => ({
  //     ...prevContent,
  //     content: [...prevContent.content, newItem],
  //   }));
  // };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTimeAgo = getTimeDifference(new Date(updatedAt));
      setTimeAgo(newTimeAgo);
    }, 60000); // Update every minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [updatedAt]);

  function generateRandomArticleName() {
    // List of adjectives and nouns to form the category name
    var adjectives = [
      "Creative",
      "Innovative",
      "Unique",
      "Dynamic",
      "Vibrant",
      "Inspiring",
      "Fresh",
    ];
    var nouns = [
      "Ideas",
      "Creations",
      "Projects",
      "Ventures",
      "Endeavors",
      "Enterprises",
    ];

    // Select a random adjective and noun
    var randomAdjective =
      adjectives[Math.floor(Math.random() * adjectives.length)];
    var randomNoun = nouns[Math.floor(Math.random() * nouns.length)];

    // Combine them to form the category name
    var categoryName = randomAdjective + " " + randomNoun;

    return categoryName;
  }

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setArticleDetails({ ...articleDetails, [name]: value });
    setChanging(!changing);
  };

  const createArticleRequest = async () => {
    let data;
    let alpsToken = localStorage.getItem("alpsToken");
    if (alpsToken && type === "create" && !articleId) {
      if (articleDetails["file"]?.file) {
        setLoading(true);
        data = await createArticle(alpsToken, {
          title: articleDetails["title"]
            ? articleDetails["title"]
            : "Untitled Article",
          file: articleDetails["file"].file,
          description: articleDetails["description"],
          articleBody: articleDetails["articleBody"],
        });
        setLoading(false);
        if (data) {
          setArticleId(data?.id);
          setUpdatedAt(data?.updatedAt);
        }
        setTimeAgo("Just now");
        setType("update");
      } else {
        setLoading(true);
        data = await createArticle(alpsToken, {
          title: articleDetails["title"]
            ? articleDetails["title"]
            : "Untitled Article",
          coverURL: articleDetails["file"]
            ? articleDetails["file"]
            : "https://www.betterinvesting.org/getmedia/0e845518-2f15-48fb-a01d-8a192aafd39d/help-center-home.png?width=800&height=452&ext=.png",
          description: articleDetails["description"],
          articleBody: articleDetails["articleBody"],
        });
        setLoading(false);
        if (data) {
          setArticleId(data?.id);
          setUpdatedAt(data?.updatedAt);
        }
        setTimeAgo("Just now");
        setType("update");
      }
    } else if ((alpsToken && type === "update") || articleId) {
      let data;
      if (articleDetails["file"]?.file) {
        setLoading(true);
        data = await updateArticle(
          alpsToken,
          {
            title: articleDetails["title"]
              ? articleDetails["title"]
              : "Untitled Article",
            file: articleDetails["file"].file,
            description: articleDetails["description"],
            articleBody: articleDetails["articleBody"],
          },
          articleId
        );
        setTimeAgo("Just now");
        setUpdatedAt(data?.updatedAt);
        setLoading(false);
      } else {
        setLoading(true);
        data = await updateArticle(
          alpsToken,
          {
            title: articleDetails["title"]
              ? articleDetails["title"]
              : "Untitled Article",
            coverURL: articleDetails["file"]
              ? articleDetails["file"]
              : "https://www.betterinvesting.org/getmedia/0e845518-2f15-48fb-a01d-8a192aafd39d/help-center-home.png?width=800&height=452&ext=.png",
            description: articleDetails["description"],
            articleBody: articleDetails["articleBody"],
          },
          articleId
        );
        setTimeAgo("Just now");
        setUpdatedAt(data?.updatedAt);
        setLoading(false);
      }
    }
    setEdited(false);
  };

  const publishArticleRequest = async () => {
    let alpsToken = localStorage.getItem("alpsToken");
    if (articleId && article?.status !== "published") {
      setPLoading(true);
      await publishArticle(
        alpsToken,
        {
          status: "published",
        },
        articleId,
        setContent,
        id,
        setPLoading,
        setPopUp,
        setModal
      );
    }
  };

  const getProfile = async () => {
    const alpsToken = localStorage.getItem("alpsToken");
    let userProfile = await getUserProfile(alpsToken);
    if (userProfile) {
      setUserProfile(userProfile);
    }
  };

  useEffect(() => {
    if (articleDetails["articleBody"]) {
      createArticleRequest();
      setType("update");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleDetails["articleBody"]]);

  useEffect(() => {
    setEdited(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    articleDetails["file"],
    articleDetails["title"],
    articleDetails["description"],
  ]);

  useEffect(() => {
    let alpsToken = localStorage.getItem("alpsToken");
    if (!alpsToken) {
      window.location.pathname = "/login";
    }
    if (article) {
      getProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="w-full cflexss h-full font-inter"
        onClick={() => {
          setIsOpenModal(false);
        }}
      >
        {popUp && modal}
        <div className="w-full flexbm text-[14px] py-[16px] px-[30px] border-b-[2px]">
          <div className="flexsm gap-[15px]">
            {article ? (
              <>
                <p className="capitalize">{article.status.toLowerCase()}</p>
                <div
                  className={`w-[8px] h-[8px] rounded-full ${
                    article.status === "published" && "bg-[#22C55E]"
                  } ${article.status === "draft" && "bg-yellow-400"} ${
                    article.status === "archived" && "bg-red-600"
                  }`}
                />
                <p className="text-[#7D7D7D]">
                  {updatedAt && `Last Saved ${timeAgo}`}
                </p>
              </>
            ) : (
              <>
                {published ? <p>Published</p> : <p>Draft</p>}
                <div
                  className={`w-[8px] h-[8px] rounded-full ${
                    published ? "bg-[#22C55E]" : "bg-yellow-400"
                  } `}
                />
                <p className="text-[#7D7D7D]">
                  {updatedAt && `Last Saved ${timeAgo}`}
                </p>
              </>
            )}
          </div>
          <div className="flexmm gap-[14px] font-[500]">
            <p
              className="cursor-pointer"
              onClick={() => {
                window.location.pathname = "/articles";
              }}
            >
              Cancel
            </p>
            <div
              className="flexmm px-[12px] py-[6px] gap-[8px] cursor-pointer rounded-[10px] bg-[#F5F5F5]"
              onClick={() => {
                if (
                  article?.status.toLowerCase() === "published" &&
                  userProfile
                ) {
                  window.location.href = `https://${
                    userProfile?.name
                  }.tryalps.app/article/${articleDetails["title"].replace(
                    / /g,
                    "-"
                  )}`;
                }
              }}
            >
              <img
                src="/globe.svg"
                className="w-[14px] h-[14px]"
                alt="globe-icon"
              />
              <p>Preview</p>
            </div>
            {article ? (
              <>
                {article?.status.toLowerCase() === "draft" ? (
                  <>
                    <div
                      className="flexmm px-[12px] py-[6px] cursor-pointer rounded-[10px] bg-[#F5F5F5]"
                      onClick={async () => {
                        setType("update");
                        await createArticleRequest();
                        if (id) {
                          window.location.pathname = "/articles";
                        } else {
                          setContent("articles");
                          setArticle("")
                        }
                      }}
                    >
                      {loading ? (
                        <div className="flexbm gap-[10px]">
                          <Loader2 /> <p>Save to draft</p>
                        </div>
                      ) : (
                        <p>Save to draft</p>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="flexmm px-[12px] py-[6px] cursor-pointer text-white rounded-[10px] bg-buttonColor"
                      onClick={async () => {
                        setType("update");
                        await createArticleRequest();
                        if (id) {
                          window.location.pathname = "/articles";
                        } else {
                          setContent("articles");
                          setArticle("")
                        }
                      }}
                    >
                      {loading ? (
                        <div className="flexbm gap-[10px]">
                          <Loader /> <p>Update</p>
                        </div>
                      ) : (
                        <p>Update</p>
                      )}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div
                  className="flexmm px-[12px] py-[6px] cursor-pointer rounded-[10px] bg-[#F5F5F5]"
                  onClick={async () => {
                    setType("create");
                    await createArticleRequest();
                    if (id) {
                      window.location.pathname = "/articles";
                    } else {
                      setContent("articles");
                      setArticle("")
                    }
                  }}
                >
                  {loading ? (
                    <div className="flexbm gap-[10px]">
                      <Loader2 /> <p>Save to draft</p>
                    </div>
                  ) : (
                    <p>Save to draft</p>
                  )}
                </div>
              </>
            )}
            {article ? (
              <>
                {article?.status.toLowerCase() !== "published" && (
                  <div className="rounded-[10px] shadow-md">
                    <Button
                      paddingX="17px"
                      paddingY="8px"
                      handleClick={() => {
                        publishArticleRequest();
                      }}
                    >
                      {pLoading ? (
                        <div className="flexmm gap-[10px]">
                          <Loader />
                          <p>Publish</p>
                        </div>
                      ) : (
                        <p>Publish</p>
                      )}
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <>
                {!published && (
                  <div className="rounded-[10px] shadow-md">
                    <Button
                      paddingX="17px"
                      paddingY="8px"
                      handleClick={() => {
                        publishArticleRequest();
                      }}
                    >
                      {pLoading ? (
                        <div className="flexmm gap-[10px]">
                          <Loader />
                          <p>Publish</p>
                        </div>
                      ) : (
                        <p>Publish</p>
                      )}
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="px-[10%] py-[40px] pb-[40px] h-full overflow-y-auto w-full cflexss gap-[40px]">
          <div className="cflexss w-full gap-[20px] pb-[20px] border-b-[2px]">
            <input
              value={articleDetails["title"]}
              name="title"
              className="w-full text-[32px] outline-none border-none"
              type="text"
              placeholder="Article Title"
              onChange={handleChange}
            />
            <div
              className={`relative z-0 w-full bg-[#D9D9D9] rounded-[10px] ${
                articleDetails["file"] ? "h-[160px]" : "h-[64px]"
              }`}
            >
              {articleDetails["file"] && (
                <>
                  {articleDetails["file"].base64 ? (
                    <img
                      src={articleDetails["file"].base64}
                      alt="cover"
                      className="w-full h-full rounded-[10px] object-cover"
                    />
                  ) : (
                    <img
                      src={articleDetails["file"]}
                      alt="cover"
                      className="w-full h-full rounded-[10px] object-cover"
                    />
                  )}
                </>
              )}

              <div
                className="absolute top-[25%] z-0 right-[22px] cursor-pointer rounded-[10px] px-[12px] py-[6px] bg-white text-[14px] font-[500]"
                onClick={(e) => {
                  setModalContent(
                    <ChangeCover
                      setIsOpenModal={setIsOpenModal}
                      setCategoryDetails={setArticleDetails}
                      categoryDetails={articleDetails}
                      setChanging={setChanging}
                      changing={changing}
                    />
                  );
                  setIsOpenModal(!isOpenModal);
                  e.stopPropagation();
                }}
              >
                Change cover
                <div className="relative">{isOpenModal && modalContent}</div>
              </div>
            </div>
            <div className="w-full cflexss gap-[11px]">
              <p className="text-[12px] text-[#989898] font-[500]">
                DESCRIPTION (Optional)
              </p>
              <textarea
                value={articleDetails["description"]}
                name="description"
                className="w-full text-[16px] outline-none border-none resize-none"
                type="text"
                placeholder="Type in the description here..."
                onChange={handleChange}
              />
            </div>
          </div>
          <Editor
            articleDetails={articleDetails}
            setArticleDetails={setArticleDetails}
          />
        </div>
      </div>
    </>
  );
};

export default AddContent;

// const MarkdownToHtml = ({ markdown }) => {
//   return <ReactMarkdown>{markdown}</ReactMarkdown>;
// };
