import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { MenuAlt3, X } from "heroicons-react";
import { Link } from "react-router-dom";

const Header = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="fixed top-0 bg-white left-0 w-full px-[56px] md:px-[20px] flexem font-inter">
        <div
          className="fixed top-[70px] left:0 hidden md:block bg-white rounded-[10px] shadow-md border-[2px] p-[20px] w-[80vw]"
          style={{ left: show ? "20px" : "-100%", transition: "0.5s" }}
        >
          <div className="cflexss gap-[10px] text-[18px] font-semibold">
            <Link
              to="/login"
              className="px-[17px] py-[8px] flexmm cursor-pointer"
            >
              <p>Sign in</p>
            </Link>
            <Link to="/register">
              <Button
                text="16px"
                paddingX="17px"
                paddingY="8px"
                handleClick={() => {}}
              >
                Start for free
              </Button>
            </Link>
          </div>
        </div>
        <div className="w-[52%] md:w-full flexbm">
          <div className="text-[#222222] font-bold text-[20px] flexmm gap-[8px] cursor-pointer">
            <Link to="/" className="w-[85px] h-[85px] flexmm">
              <img src="/logoAlps.svg" className="w-full h-full" alt="logo" />
            </Link>
          </div>
          {show ? (
            <X
              size="27px"
              className="hidden md:block cursor-pointer"
              onClick={() => {
                setShow(false);
              }}
            />
          ) : (
            <MenuAlt3
              size="30px"
              className="hidden md:block cursor-pointer"
              onClick={() => {
                setShow(true);
              }}
            />
          )}
          <div className="md:hidden flexmm gap-[10px] text-[18px] font-semibold">
            <Link
              to="/login"
              className="px-[17px] py-[8px] flexmm cursor-pointer"
            >
              <p>Sign in</p>
            </Link>
            <Link to="/register">
              <Button
                text="16px"
                paddingX="17px"
                paddingY="8px"
                handleClick={() => {}}
              >
                Start for free
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
