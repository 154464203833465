import React, { useState, useEffect } from "react";
import NavBar from "../collections/NavBar";
import { CheckCircleOutline } from "heroicons-react";
import Button from "../../components/Button";

const FormsContent = () => {
  const [bugs, setBugs] = useState([
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
  ]);
  const [features, setFeatures] = useState([
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
  ]);
  const [questions, setQuestions] = useState([
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
  ]);
  const [searchQuery, setSearchQuery] = useState("");
  const [view, setView] = useState("");
  const [formType, setFormType] = useState("Bugs");

  const filteredBugs = bugs?.filter((bug) =>
    bug.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredFeatures = features?.filter((feature) =>
    feature.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredQuestions = questions?.filter((question) =>
    question.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <>
      <NavBar active="Forms" />
      <div className={`w-full flexss h-full font-inter mt-[95px]`}>
        <div
          className="w-[330px] cflexss pb-[40px] border-r-[1px] text-primary1 flex-shrink overflow-y-auto"
          style={{ height: "calc(100vh - 95px)" }}
        >
          <div className="w-full px-[22px] py-[14px] cflexmm gap-[10px] border-b-[2px]">
            <form
              className="w-full rounded-[15px] px-[12px] py-[8px] flexsm gap-[12px] border-[1px]"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <img
                src="/search-icon.svg"
                alt="search-icon"
                className="w-[20px] h-[20px] cursor-pointer"
              />
              <input
                name="searchQuery"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                placeholder="Search anything"
                className="outline-none w-full"
              />
            </form>
            <div className="w-full flexbm rounded-[10px] text-[#8C8C8C] bg-[#FAFAFA] px-[5px] py-[9px]">
              <div
                className={`flexmm rounded-[10px] px-[7px] py-[4px] gap-[4px] font-medium text-[14px] cursor-pointer ${
                  formType === "Bugs" && "bg-white text-[#000000]"
                } transition-all duration-300`}
                onClick={() => {
                  setFormType("Bugs");
                }}
              >
                <p>Bugs</p>
                <div className="bg-[#1072E5] w-[15px] h-[15px] flexmm rounded-full text-white font-medium text-[10px]">
                  5
                </div>
              </div>

              <div
                className={`flexmm rounded-[10px] px-[7px] py-[4px] gap-[4px] font-medium text-[14px] cursor-pointer ${
                  formType === "Features" && "bg-white text-[#000000]"
                } transition-all duration-300`}
                onClick={() => {
                  setFormType("Features");
                }}
              >
                <p>Features</p>
                <div className="bg-[#1072E5] w-[15px] h-[15px] flexmm rounded-full text-white font-medium text-[10px]">
                  5
                </div>
              </div>

              <div
                className={`flexmm rounded-[10px] px-[7px] py-[4px] gap-[4px] font-medium text-[14px] cursor-pointer ${
                  formType === "Questions" && "bg-white text-[#000000]"
                } transition-all duration-300`}
                onClick={() => {
                  setFormType("Questions");
                }}
              >
                <p>Questions</p>
                <div className="bg-[#1072E5] w-[15px] h-[15px] flexmm rounded-full text-white font-medium text-[10px]">
                  5
                </div>
              </div>
            </div>
          </div>
          <div className="w-full cflexsm">
            {formType === "Bugs" &&
              filteredBugs.map((bug, index) => {
                return (
                  <>
                    <div
                      className="w-full flexbm px-[22px] py-[14px] gap-[9px] border-b-[1px] cursor-pointer hover:bg-[#F5F5F5] transition-all duration-300"
                      key={index}
                    >
                      <div className="cflexss text-[14px] font-medium text-black">
                        <p>{bug.title}</p>
                        <p className="text-[12px] font-normal line-clamp-1 text-wrap">
                          {bug.description}
                        </p>
                      </div>
                      <div className="cflexse text-[12px] gap-[5px]">
                        <p>{bug.date}</p>
                        {bug.state === "New" && (
                          <div className="flexmm px-[3px] py-[4px]  text-[#1072E5] rounded-[3px] bg-[#1072E5]/10">
                            <p>{bug.state}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}

            {formType === "Features" &&
              filteredFeatures.map((bug, index) => {
                return (
                  <>
                    <div
                      className="w-full flexbm px-[22px] py-[14px] gap-[9px] border-b-[1px] cursor-pointer hover:bg-[#F5F5F5] transition-all duration-300"
                      key={index}
                    >
                      <div className="cflexss text-[14px] font-medium text-black">
                        <p>{bug.title}</p>
                        <p className="text-[12px] font-normal line-clamp-1 text-wrap">
                          {bug.description}
                        </p>
                      </div>
                      <div className="cflexse text-[12px] gap-[5px]">
                        <p>{bug.date}</p>
                        {bug.state === "New" && (
                          <div className="flexmm px-[3px] py-[4px]  text-[#1072E5] rounded-[3px] bg-[#1072E5]/10">
                            <p>{bug.state}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}

            {formType === "Questions" &&
              filteredQuestions.map((bug, index) => {
                return (
                  <>
                    <div
                      className="w-full flexbm px-[22px] py-[14px] gap-[9px] border-b-[1px] cursor-pointer hover:bg-[#F5F5F5] transition-all duration-300"
                      key={index}
                    >
                      <div className="cflexss text-[14px] font-medium text-black">
                        <p>{bug.title}</p>
                        <p className="text-[12px] font-normal line-clamp-1 text-wrap">
                          {bug.description}
                        </p>
                      </div>
                      <div className="cflexse text-[12px] gap-[5px]">
                        <p>{bug.date}</p>
                        {bug.state === "New" && (
                          <div className="flexmm px-[3px] py-[4px]  text-[#1072E5] rounded-[3px] bg-[#1072E5]/10">
                            <p>{bug.state}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        <div
          style={{ height: "calc(100vh - 95px)", width: "calc(100vw - 330px)" }}
        >
          {view === "" && (
            <>
              <div className="w-full cflexss gap-[10px] px-[124px] py-[38px] text-[#36383A]">
                <div className="w-full cflexss gap-[15px]">
                  <div className="flexbm w-full">
                    <div className="flexsm gap-[8px] text-[16px]">
                      <div className="font-medium flexmm bg-[#9747FF] text-white px-[13px] py-[6px]">
                        <p>{formType}</p>
                      </div>
                      <p className="font-normal">July 10, 2024</p>
                    </div>
                    <div className="flexem gap-[16px] text-[14px]">
                      <button className="px-[12px] py-[6px] flexmm gap-[8px] bg-[#F5F5F5] rounded-[10px]">
                        <CheckCircleOutline size="20px" />
                        <p>Mark as resolved</p>
                      </button>
                      <Button
                        paddingX="12px"
                        paddingY="6px"
                        gap="12px"
                        rounded="10px"
                      >
                        <p>Respond via email</p>
                      </Button>
                    </div>
                  </div>
                  <p className="text-[32px] font-medium text-[#36383A]">
                    This is what a subject of the support looks like
                  </p>
                </div>
                <div className="cflexss gap-[49px]">
                  <div className="cflexss gap-[11px] text-[16px] font-normal">
                    <p className="text-[18px] font-semibold">Message</p>
                    <div className="cflexss">
                      <p>Hi Team,</p>
                      <p>
                        This help center article provides a step-by-step guide
                        on performing common file operations using the command
                        line interface (CLI). Users will learn essential
                        commands. This help center article provides a
                        step-by-step guide on performing common file operations
                        using the command line interface (CLI). Users will learn
                        essential commands
                      </p>
                    </div>
                  </div>
                  <div className="clfexss gap-[11px] text-[16px] font-normal">
                    <p className="text-[18px] font-semibold">Attachment(s)</p>
                    <p className="text-[#1072E5] cursor-pointer">
                      Payment Receipt.pdf
                    </p>
                    <p className="text-[#1072E5] cursor-pointer">
                      Payment Receipt.pdf
                    </p>
                    <p className="text-[#1072E5] cursor-pointer">
                      Payment Receipt.pdf
                    </p>
                    <p className="text-[#1072E5] cursor-pointer">
                      Payment Receipt.pdf
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FormsContent;
