import React from "react";
import Header from "./Header";
import HeroSection from "./HeroSection";
import Body from "./Body";
import Footer from "./Footer";

const HomePage = ({ designInfo, userProfile }) => {
  return (
    <>
      <div className="relative cflexss w-full font-inter">
        <Header designInfo={designInfo} />
        {userProfile?.profile?.themeType !== 2 && (
          <HeroSection
            designInfo={designInfo}
            userProfile={userProfile}
            background={true}
          />
        )}
        <Body designInfo={designInfo} userProfile={userProfile} />
        <Footer designInfo={designInfo} />
      </div>
    </>
  );
};

export default HomePage;
