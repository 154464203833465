import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Search,
  InformationCircleOutline,
  ThumbUpOutline,
  ThumbDownOutline,
} from "heroicons-react";
import SearchModal from "../../components/SearchModal";
import Settings from "../../components/Settings";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "../../../node_modules/react-loading-skeleton/dist/skeleton.css";
import { getUserData, getUserProfile } from "../../services/request";

const NavBar = ({ active, type }) => {
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [sModal, setSModal] = useState(false);
  const [userData, setUserData] = useState("");
  const [userProfile, setUserProfile] = useState("");

  const navItems = [
    {
      name: "Articles",
      link: "collections",
    },
    {
      name: "Guides",
      link: "guides",
    },
    {
      name: "Forms",
      link: "forms",
    },
    {
      name: "Reports",
      link: "reports",
    },
  ];

  const getUrlData = () => {
    const url = window.location.href;
    // Extract the query string from the URL
    const queryString = url.split("?")[1];

    // Extract the token and email using regular expressions
    const tokenRegex = /token=([^&]+)/;
    let tokenMatch;
    if (queryString) {
      tokenMatch = queryString?.match(tokenRegex);
    }

    const token = tokenMatch ? decodeURIComponent(tokenMatch[1]) : null;

    if (token) {
      let prevToken = localStorage.getItem("alpsToken");
      if (!prevToken) {
        localStorage.setItem("alpsToken", token);
      }
      window.location.href = "https://tryalps.com/collections";
    }
  };

  const getUser = async () => {
    getUrlData();
    const alpsToken = localStorage.getItem("alpsToken");
    if (alpsToken) {
      let userData = await getUserData(alpsToken);
      console.log(userData);
      if (userData?.isVerified) {
        let userProfile = await getUserProfile(alpsToken);
        console.log(userProfile);
        setUserProfile(userProfile);
      }
      if (userData && userData?.firstName && userData?.lastName) {
        setUserData(userData);
      } else {
        navigate("/info");
      }
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isOpenModal && <SearchModal setIsOpenModal={setIsOpenModal} />}
      {sModal && (
        <Settings
          setSModal={setSModal}
          userData={userData}
          userProfile={userProfile}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}

      <div className="fixed z-50 top-0 left-0 w-full cflexss bg-primary2 font-inter text-[16px] text-[#F2F2F2]">
        <div className="w-full flexbm px-[20px] py-[15px] gap-[20px]">
          <ul className="flexsm gap-[30px]" style={{ listStyleType: "none" }}>
            {navItems.map((item, index) => {
              return (
                <div
                  key={index}
                  className="cflexmm gap-[5px] cursor-pointer transition-all"
                  onClick={() => {
                    window.location.pathname = `/${item.link}`;
                  }}
                >
                  <li
                    className={`px-[9px] py-[4px] rounded-[4px] ${
                      active === item.name && "bg-[#4E4D4D]"
                    } transition-all`}
                  >
                    {item.name}
                  </li>
                  {active === item.name && (
                    <div className="w-full h-[3px] rounded-full bg-white" />
                  )}
                </div>
              );
            })}
            <div
              className="flexmm gap-[12px] cursor-pointer"
              onClick={() => {
                setIsOpenModal(true);
              }}
            >
              <Search size="20px" /> <p>Search</p>
            </div>
          </ul>
          <div className="flexmm gap-[30px] text-[16px] text-[#F2F2F2]">
            <div
              className="cflexmm gap-[5px] cursor-pointer transition-all"
              onClick={() => {
                if (
                  userProfile?.profile &&
                  userProfile?.profile?.themeType === 0
                ) {
                  window.location.pathname = "/select-theme";
                } else if (
                  !userProfile?.profile ||
                  userProfile?.profile?.themeType > 0
                ) {
                  window.location.pathname = "/design";
                }
              }}
            >
              <div
                className={`px-[9px] py-[4px] rounded-[4px] ${
                  (active === "Design" || active === "SelectTheme") &&
                  "bg-[#4E4D4D]"
                } transition-all`}
              >
                Design
              </div>
              {(active === "Design" || active === "SelectTheme") && (
                <div className="w-full h-[3px] rounded-full bg-white" />
              )}
            </div>
            <div
              className="relative flexmm gap-[10px] px-[9px] py-[4px]"
              onClick={() => {
                setModal(!modal);
              }}
            >
              {modal && (
                <>
                  <div className="fixed z-[80] h-full w-full shadow-md bg-[#000000]/10 flexes top-0 left-0 pt-[85px] pr-[20px]">
                    <div
                      className="rounded-[10px] bg-[#222222] w-[186px] flex-shrink cflexss p-[10px] gap-[6px]"
                      onClick={(e) => {
                        e.stopPropagation();
                        setModal(false);
                      }}
                    >
                      <div
                        className="rounded-[10px] px-[9px] py-[6px] w-full hover:bg-[#4E4D4D] cursor-pointer"
                        onClick={() => {
                          setSModal(true);
                        }}
                      >
                        <p>Settings</p>
                      </div>
                      <div
                        className="rounded-[10px] px-[9px] py-[6px] w-full hover:bg-[#4E4D4D] cursor-pointer"
                        onClick={() => {
                          localStorage.clear("alpsToken");
                          window.location.pathname = "/login";
                        }}
                      >
                        <p>Log out</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {userData ? (
                <>
                  <div className="flexmm gap-[14px] cursor-pointer">
                    <div className="w-[35px] h-[35px] text-[14px] rounded-full bg-green-600 text-white font-bold flexmm">
                      {userData?.firstName?.charAt(0)}{" "}
                      {userData?.lastName?.charAt(0)}
                    </div>
                    <div className="cflexss text-[12px]">
                      <p className="font-medium">
                        {userData?.firstName} {userData?.lastName}
                      </p>
                      <p className="text-[#656667]">{userData?.email}</p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <SkeletonTheme baseColor="#f5f5f5" highlightColor="#cacecf">
                    <div className="w-full flexmm gap-[14px]">
                      <Skeleton circle width={"35px"} height={"35px"} />
                      <div className="cflexss">
                        <Skeleton
                          width={"160px"}
                          height={"10px"}
                          borderRadius={"12px"}
                        />
                        <Skeleton
                          width={"160px"}
                          height={"10px"}
                          borderRadius={"12px"}
                        />
                      </div>
                    </div>
                  </SkeletonTheme>
                </>
              )}
              <div className="cursor-pointer">
                <img src="/settings.svg" alt="settings" />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`px-[20px] pb-[20px] ${
            active !== "Guides" && "pt=[34px]"
          } w-full flexbm gap-[20px]`}
        >
          {active === "Articles" && (
            <>
              <div className="cflexss gap-[24px]">
                <div className="flexmm font-semibold text-[22px] gap-[10px]">
                  <p>Articles</p>
                  <InformationCircleOutline size="20px" />
                </div>
                <div className="flexss gap-[30px]">
                  <div
                    className={`cflexss pb-[9px] ${
                      type === "Folders"
                        ? "border-b-[3px] border-white"
                        : "text-[#7C7D7E]"
                    } cursor-pointer `}
                    onClick={() => {
                      window.location.pathname = "/collections";
                    }}
                  >
                    <p>Folders</p>
                  </div>
                  <div
                    className={`cflexss pb-[9px] ${
                      type === "allArticles"
                        ? "border-b-[3px] border-white"
                        : "text-[#7C7D7E]"
                    } cursor-pointer`}
                    onClick={() => {
                      window.location.pathname = "/articles";
                    }}
                  >
                    <p>Manage all articles</p>
                  </div>
                </div>
              </div>
              <div className="flexmm gap-[11px]">
                <div className=" w-[228px] p-[12px] cflexss gap-[13px] bg-[#323232] font-medium text-[14px] rounded-[7px]">
                  <div className="w-full flexbm text-[#D4D4D4]">
                    <p>Total Articles</p>
                    <InformationCircleOutline size="20px" />
                  </div>
                  <p className="text-[24px]">
                    {userProfile && userProfile?.articleStats?.totalArticles}
                  </p>
                </div>
                <div className=" w-[228px] p-[12px] cflexss gap-[13px] bg-[#323232] font-medium text-[14px] rounded-[7px]">
                  <div className="w-full flexbm text-[#D4D4D4]">
                    <p>Article Views</p>
                    <InformationCircleOutline size="20px" />
                  </div>
                  <p className="text-[24px]">
                    {userProfile && userProfile?.articleStats?.totalViews}
                  </p>
                </div>
                <div className="p-[12px] cflexss gap-[13px] bg-[#323232] font-medium text-[14px] rounded-[7px]">
                  <div className="w-full flexbm text-[#D4D4D4]">
                    <p>Article ratings (all time)</p>
                    <InformationCircleOutline size="20px" />
                  </div>
                  <div className="flexsm gap-[20px]">
                    <div className="flexsm gap-[5px] text-[24px]">
                      <ThumbUpOutline size="20px" color="#1072E5" />
                      <p>
                        {userProfile &&
                          userProfile?.articleStats?.upVotePercentage}
                        %
                      </p>
                    </div>
                    <div className="flexsm gap-[5px] text-[24px]">
                      <ThumbDownOutline size="20px" />
                      <p>
                        {userProfile &&
                          userProfile?.articleStats?.downVotePercentage}
                        %
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {active === "Guides" && (
            <>
              <div className="cflexss gap-[24px]">
                <div className="flexmm font-semibold text-[22px] gap-[10px]">
                  <p>Guides</p>
                  <InformationCircleOutline size="20px" />
                </div>
                <div className="flexmm gap-[11px]">
                  <div className=" w-[228px] p-[12px] cflexss gap-[13px] bg-[#323232] font-medium text-[14px] rounded-[7px]">
                    <div className="w-full flexbm text-[#D4D4D4]">
                      <p>Total Guides</p>
                      <InformationCircleOutline size="20px" />
                    </div>
                    <p className="text-[24px]">150</p>
                  </div>
                  <div className=" w-[228px] p-[12px] cflexss gap-[13px] bg-[#323232] font-medium text-[14px] rounded-[7px]">
                    <div className="w-full flexbm text-[#D4D4D4]">
                      <p>Guide Views</p>
                      <InformationCircleOutline size="20px" />
                    </div>
                    <p className="text-[24px]">150</p>
                  </div>
                  <div className=" w-[228px] p-[12px] cflexss gap-[13px] bg-[#323232] font-medium text-[14px] rounded-[7px]">
                    <div className="w-full flexbm text-[#D4D4D4]">
                      <p>Guide ratings (all time)</p>
                      <InformationCircleOutline size="20px" />
                    </div>
                    <div className="flexsm gap-[20px]">
                      <div className="flexsm gap-[5px] text-[24px]">
                        <ThumbUpOutline size="20px" color="#1072E5" />
                        <p>70%</p>
                      </div>
                      <div className="flexsm gap-[5px] text-[24px]">
                        <ThumbDownOutline size="20px" />
                        <p>30%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {active === "Reports" && (
            <>
              <div className="w-full cflexss gap-[24px]">
                <div className="flexmm font-semibold text-[22px] gap-[10px]">
                  <p>Analytics</p>
                  <InformationCircleOutline size="20px" />
                </div>
                <div className="w-full flexmm gap-[11px]">
                  <div className=" w-[228px] p-[12px] cflexss gap-[13px] bg-[#323232] font-medium text-[14px] rounded-[7px] flex-shrink">
                    <div className="w-full flexbm text-[#D4D4D4]">
                      <p>Total Articles</p>
                      <InformationCircleOutline size="20px" />
                    </div>
                    <p className="text-[24px]">
                      {userProfile && userProfile?.articleStats?.totalArticles}
                    </p>
                  </div>
                  <div className=" w-[228px] p-[12px] cflexss gap-[13px] bg-[#323232] font-medium text-[14px] rounded-[7px] flex-shrink">
                    <div className="w-full flexbm text-[#D4D4D4]">
                      <p>Article Views</p>
                      <InformationCircleOutline size="20px" />
                    </div>
                    <p className="text-[24px]">
                      {userProfile && userProfile?.articleStats?.totalViews}
                    </p>
                  </div>
                  <div className="p-[12px] cflexss gap-[13px] bg-[#323232] font-medium text-[14px] rounded-[7px] flex-shrink">
                    <div className="w-full flexbm text-[#D4D4D4]">
                      <p>Article ratings (all time)</p>
                      <InformationCircleOutline size="20px" />
                    </div>
                    <div className="flexsm gap-[20px]">
                      <div className="flexsm gap-[5px] text-[24px]">
                        <ThumbUpOutline size="20px" color="#1072E5" />
                        <p>
                          {userProfile &&
                            userProfile?.articleStats?.upVotePercentage}
                          %
                        </p>
                      </div>
                      <div className="flexsm gap-[5px] text-[24px]">
                        <ThumbDownOutline size="20px" />
                        <p>
                          {userProfile &&
                            userProfile?.articleStats?.downVotePercentage}
                          %
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" w-[228px] p-[12px] cflexss gap-[13px] bg-[#323232] font-medium text-[14px] rounded-[7px] flex-shrink">
                    <div className="w-full flexbm text-[#D4D4D4]">
                      <p>Total Videos</p>
                      <InformationCircleOutline size="20px" />
                    </div>
                    <p className="text-[24px]">150</p>
                  </div>
                  <div className="p-[12px] cflexss gap-[13px] bg-[#323232] font-medium text-[14px] rounded-[7px] flex-shrink">
                    <div className="w-full flexbm text-[#D4D4D4]">
                      <p>Video ratings (all time)</p>
                      <InformationCircleOutline size="20px" />
                    </div>
                    <div className="flexsm gap-[20px]">
                      <div className="flexsm gap-[5px] text-[24px]">
                        <ThumbUpOutline size="20px" color="#1072E5" />
                        <p>70%</p>
                      </div>
                      <div className="flexsm gap-[5px] text-[24px]">
                        <ThumbDownOutline size="20px" />
                        <p>30%</p>
                      </div>
                    </div>
                  </div>
                  <div className=" w-[228px] p-[12px] cflexss gap-[13px] bg-[#323232] font-medium text-[14px] rounded-[7px] flex-shrink">
                    <div className="w-full flexbm text-[#D4D4D4]">
                      <p>Empty Searches</p>
                      <InformationCircleOutline size="20px" />
                    </div>
                    <p className="text-[24px]">150</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NavBar;
