import React, { useState, useEffect, Fragment } from "react";
import ReactPaginate from "react-paginate";
import { ArrowLeft, ArrowRight, DotsVertical, Menu } from "heroicons-react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Paginated, GetPaginatedData } from "../../atomicComponents/Pagination";
import { Popover, Transition } from "@headlessui/react";
import MoveArticle from "../../components/MoveArticle";
import { getCategoryArticles, reorderArticle } from "../../services/request";

const TableOfArticle = ({
  setIsOpenModal,
  setModalContent,
  categoryId,
  category,
  setRefresh,
  refresh,
  searchQuery,
  articles,
  setArticles,
  userProfile,
}) => {
  const MenuContent = [
    {
      name: "Edit article",
    },
    {
      name: "Move article",
    },
    {
      name: "View in help center",
    },
    // {
    //   name: "Archive",
    // },
  ];
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const PAGINATION = 5;
  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
    // Fetch and display data for the selected page
  };

  const onDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const articleReorder = async (newItems) => {
    let alpsToken = localStorage.getItem("alpsToken");
    let customized = [];
    for (let i = 0; i < newItems.length; i++) {
      customized.push({
        id: newItems[i].id,
        order: i + 1,
      });
    }
    await reorderArticle(alpsToken, { articles: customized });
  };

  const onDrop = (e, newIndex) => {
    const draggedIndex = e.dataTransfer.getData("index");
    const newItems = [...articles];
    const [removed] = newItems.splice(draggedIndex, 1);
    newItems.splice(newIndex, 0, removed);
    setArticles(newItems);
    articleReorder(newItems);
  };

  const getLinkedArticles = async () => {
    let alpsToken = localStorage.getItem("alpsToken");
    let data = await getCategoryArticles(categoryId, alpsToken);
    setArticles(data);
    setPageCount(Paginated(data, PAGINATION));
  };

  useEffect(() => {
    if (categoryId) {
      getLinkedArticles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, refresh]);

  const filteredArticles = articles.filter((article) =>
    article.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <>
      <table className="w-full p-0 m-0">
        {/* <thead scope="column" className="text-[16px] text-[#4D4E51]"> */}
        <thead className="text-[16px] text-[#4D4E51]">
          <tr className="w-full bg-[#F5F5F5]">
            <td className="px-[24px] py-[16px] flexsm font-[600] gap-[16px]">
              <p>Article name</p>
            </td>
            <td className="px-[24px] py-[16px] font-[600]">
              <p>Status</p>
            </td>
            <td className="px-[24px] py-[16px] font-[600]">
              <p>Views</p>
            </td>
            <td className="px-[24px] py-[16px] font-[600]">
              <p>Helpful</p>
            </td>
            <td className="px-[24px] py-[16px] font-[600]">
              <p>Not Helpful</p>
            </td>
          </tr>
        </thead>
        <tbody className="w-full">
          {articles.length > 0 &&
            GetPaginatedData(currentPage, PAGINATION, filteredArticles)?.map(
              (article, index) => {
                return (
                  <>
                    <tr
                      key={index}
                      draggable
                      onDragStart={(e) => onDragStart(e, index)}
                      onDragOver={onDragOver}
                      onDrop={(e) => onDrop(e, index)}
                      className="cursor-pointer border-b-[1px] bg-white hover:bg-[#f5f5f5]"
                      onClick={() => {
                        window.location.pathname = `/articles/${article.id}`;
                      }}
                    >
                      <td className="px-[24px] flexsm gap-[16px] py-[16px]">
                        <img
                          src="/move1.svg"
                          alt="move-icon"
                          draggable={false}
                        />
                        <div className="w-[20px] h-[20px] text-[12px] bg-gray-200 flexmm rounded-full">
                          {index + 1}
                        </div>
                        <p>{article.title}</p>
                      </td>
                      <td className="px-[24px] py-[16px]">
                        <div className="flexsm gap-[8px]">
                          <div
                            className={`w-[8px] h-[8px] rounded-full ${
                              article.status === "published" && "bg-[#22C55E]"
                            } ${
                              article.status === "draft" && "bg-yellow-400"
                            } ${
                              article.status === "archived" && "bg-red-600"
                            } capitalize`}
                          />
                          <p className="capitalize">{article.status}</p>
                        </div>
                      </td>
                      <td className="px-[24px] font-[700] py-[16px]">
                        <p>{article.views}</p>
                      </td>
                      <td className="px-[24px] font-[700] py-[16px]">
                        <p>{article.upVoteCount}</p>
                      </td>
                      <td className="px-[24px] font-[700] py-[16px] flexbm gap-[16px]">
                        <p>{article.downVoteCount}</p>
                        <Popover
                          className="relative"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {({ open, close }) => (
                            <>
                              <Popover.Button
                                className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex items-center rounded-md bg-transparent px-0 py-0 text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                              >
                                <DotsVertical
                                  className={`${open ? "" : "text-opacity-70"}
                  ml-0 h-4 w-4 text-[#7d7d7d] transition duration-150 ease-in-out group-hover:text-opacity-80`}
                                  aria-hidden="true"
                                />
                              </Popover.Button>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                              >
                                <Popover.Panel className="absolute right-0 z-[10] mt-0 w-[192px] transform">
                                  <div className="relative py-[8px] bg-white flex-shrink shadow-xl">
                                    {MenuContent.map((content, index) => {
                                      return (
                                        <>
                                          <p
                                            key={index}
                                            className="px-[16px] py-[8px] text-[14px] font-medium hover:bg-[#f5f5f5] cursor-pointer"
                                            onClick={(e) => {
                                              if (
                                                content.name === "Move article"
                                              ) {
                                                setIsOpenModal(true);
                                                e.stopPropagation();
                                                setModalContent(
                                                  <MoveArticle
                                                    setIsOpenModal={
                                                      setIsOpenModal
                                                    }
                                                    article={article}
                                                    refresh={refresh}
                                                    setRefresh={setRefresh}
                                                    category={category}
                                                  />
                                                );
                                              } else if (
                                                content.name === "Edit article"
                                              ) {
                                                window.location.pathname = `/articles/${article.id}`;
                                              } else if (
                                                content.name ===
                                                  "View in help center" &&
                                                userProfile &&
                                                article.status.toLowerCase() ===
                                                  "published"
                                              ) {
                                              window.open(`https://${
                                                  userProfile?.profile.name
                                                }.tryalps.app/article/${article.title.replace(
                                                  / /g,
                                                  "-"
                                                )}`, '_blank');
                                              }
                                              close();
                                            }}
                                          >
                                            {content.name}
                                          </p>
                                        </>
                                      );
                                    })}
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            </>
                          )}
                        </Popover>
                      </td>
                    </tr>
                  </>
                );
              }
            )}
        </tbody>
      </table>
      {articles.length === 0 && (
        <div className="w-full">
          <SkeletonTheme baseColor="#f5f5f5" highlightColor="#cacecf">
            {new Array(6).fill().map((x, index) => (
              <Skeleton
                key={index}
                containerClassName="w-full h-[80px] rounded-[10px]"
                className="w-full h-[100vh]"
                width={"100%"}
                height={"45px"}
                borderRadius={"5px"}
              />
            ))}
          </SkeletonTheme>
        </div>
      )}
      <div className="w-full flexem mt-[10px]">
        <ReactPaginate
          previousLabel={<ArrowLeft size="20px" />}
          previousClassName={"p-[10px] border-[2px] rounded-l-[10px]"}
          nextClassName={"p-[10px] border-[2px] rounded-r-[10px]"}
          nextLabel={<ArrowRight size="20px" />}
          breakLabel={"..."}
          breakClassName={"w-full p-[10px]"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          pageClassName={"px-[20px] py-[10px] border-[2px]"}
          onPageChange={handlePageClick}
          containerClassName={"text-[14px] lg:text-[12px] flexem"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
};

export default TableOfArticle;
