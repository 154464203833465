import React, { useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import { forgotPassword } from "../services/request";
import TermsPrivacy from "./collections/TermsPrivacy";

const Forgot = () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");  
  const [modal, setModal] = useState(null);
  const [popUp, setPopUp] = useState(false);

  const sendEmailRequest = async () => {
    await forgotPassword({ email }, setPopUp, setModal);
    setLoading(false);
    setEmail("");
  };

  const handeSubmit = (e) => {
    e.preventDefault();
    if (email && emailRegex.test(email)) {
      setLoading(true);
      sendEmailRequest();
    }
  };
  return (
    <>
      {popUp && modal}
      <div className="w-full h-[100vh] cflexbm text-[16px] pt-[32px] pb-[50px] font-inter">
        <Link to="/" className="w-[85px] h-[85px] flexmm shadow-sm">
          <img src="/logoAlps.svg" className="w-full h-full" alt="logo" />
        </Link>
        <form
          className="m-[10px] py-[50px] px-[40px] w-[400px] cflexsm flex-shrink pt-[64px] pb-[70px]"
          onSubmit={handeSubmit}
        >
          <p className="text-[29.63px] text-center font-[700] leading-[48px] pb-[24px] text-[#2D333A]">
            Reset your password
          </p>
          <p className="text-[14px] text-center pb-[15px]">
            Enter your email and we will send you instructions to reset your
            password
          </p>
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Email address"
            className=" border-[2px] shadow-sm w-full p-[10px] mb-[15px] rounded-[8px] outline-none"
          />
          <button
            type="submit"
            className="text-[16px] mb-[24px] text-white font-[600] border-none px-[16px] py-[8px] flexmm rounded-[8px] w-full bg-buttonColor"
          >
            {loading ? <Loader /> : <p className="leading-[22px]">Continue</p>}
          </button>
          <div className="relative pb-[30px] border-b-[2px] mb-[24px] w-full flexmm">
            <p>
              Go back to{" "}
              <Link to="/login" className="underline">
                {" "}
                Log in
              </Link>
            </p>
            <p className="absolute text-center bottom-[-15px] text-[#707070] bg-white p-[5px]">
              OR
            </p>
          </div>
          <div className="w-full rounded-[4px] border-[2px] flexmm gap-[12px] px-[16px] py-[12px] cursor-pointer">
            <img
              src="/google-icn.svg"
              alt="google-icon"
              className="w-[24px] h-[24px]"
            />
            <p>Continue with Google</p>
          </div>
        </form>
        <TermsPrivacy />
      </div>
    </>
  );
};

export default Forgot;
