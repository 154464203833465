import React from "react";

const Header = ({ designInfo }) => {
  return (
    <>
      <div
        className="absolute top-0 left-0 w-full px-[56px] py-[12px] flexbm font-inter bg-white"
        style={{
          fontFamily: designInfo.headingFont,
        }}
      >
        <div className="flexmm gap-[10px] text-[16px] font-medium cursor-pointer">
          <img
            src={
              designInfo.headerLogo ? designInfo.headerLogo.base64 : "/logo.svg"
            }
            alt="alps-logo"
            className="w-auto h-[32px] object-oontain"
          />
        </div>
        <div
          className="flexmm gap-[16px] text-[14px]"
          style={{ color: designInfo?.brandColour }}
        >
          {designInfo?.headerLinks && designInfo?.headerLinks[0] && (
            <>
              {designInfo.headerLinks.map((link, index) => (
                <p className="cursor-pointer" key={index}>
                  {link.name}
                </p>
              ))}
            </>
          )}
          <button
            className="hover:bg-buttonHover rounded-[10px] font-inter px-[16px] py-[8px] flexmm font-[500] text-[14px] outline-none"
            style={{
              color: designInfo.textColour,
              background: designInfo.brandColour,
              fontFamily: designInfo.headingFont,
            }}
          >
            <p>Submit request</p>
          </button>
        </div>
      </div>
    </>
  );
};

export default Header;
