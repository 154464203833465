import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { motion, AnimatePresence } from "framer-motion";

const HeroSection = () => {
  const [content, setContent] = useState("Your teams craft the features");
  const DynamicContent = [
    "Your teams craft the features",
    "Your engineers have done the hard work!",
    "Your designers have mapped your vision",
    "Your marketers have tried, so have tech support",
  ];

  useEffect(() => {
    let i = 0;
    let interval = setInterval(() => {
      setContent(DynamicContent[i]);
      i++;
      if (i === DynamicContent.length) {
        i = 0;
      }
    }, 3000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mt-[100px] md:mt-[60px] w-full px-[56px] md:px-[20px] cflexmm gap-[20px] pt-[40px] font-inter">
        <div className="md:hidden text-[30px] md:text-[20px] font-medium px-[27px] py-[8px] text-white rounded-[13px] bg-[#4285F4] transition-transform duration-1000">
          <p>{content}</p>
        </div>
        <div className="text-[80px] md:text-[48px] w-full md:leading-[58px] font-[500] leading-[83px] text-center md:text-start font-inter">
          <p>Let's help your customers</p>
          <p>
            <span className="font-bold text-[#34A853]">understand</span> it.
          </p>
        </div>
        <p className="text-[20px] font-medium w-1/2 md:w-full md:mt-[60px] text-center text-[#4D4E51] md:text-start p-[10px] md:p-0">
          Create comprehensive guides, tutorials, and documentation that
          provides your users with the knowledge they need to succeed.
        </p>
        <div className="flexmm w-full gap-[10px] md:gap-[32px] md:justify-start text-[16px] font-semibold md:mt-[23px] mt-[10px]">
          <Link to="/register">
            <Button
              text="16px"
              paddingX="17px"
              paddingY="8px"
              handleClick={() => {}}
            >
              Start for free
            </Button>
          </Link>
          <Link to="/" className="px-[17px] py-[8px] flexmm cursor-pointer">
            <p>See it live</p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
