import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ designInfo }) => {
  return (
    <>
      <div
        className={`w-full ml-0 cflexss border-t-[1px] bg-white`}
        style={{
          fontFamily: designInfo?.textFont,
        }}
      >
        <div className="w-full flexbm px-[56px] sm:px-[20px] py-[21px] gap-[10px]">
          <Link to="/">
            <img
              src={
                designInfo?.headerLogo
                  ? designInfo.headerLogo.base64
                  : "/alps-logo.svg"
              }
              alt="footer logo"
              className="w-auto h-[32px] object-contain"
            />
          </Link>
          <div className="flexsm gap-[10px]">
            <Link to={`${designInfo?.headerContactPageLink}`}>
              <img src={"/alps-youtube.svg"} alt="alps youtube" />
            </Link>
            <Link to={`${designInfo?.headerContactPageLink}`}>
              <img src={"/alps-facebook.svg"} alt="alps facebook" />
            </Link>
            <Link to={`${designInfo?.headerContactPageLink}`}>
              <img src={"/alps-linkedin.svg"} alt="alps linkedin" />
            </Link>
            <Link to={`${designInfo?.headerContactPageLink}`}>
              <img src={"/alps-x.svg"} alt="alps X" />
            </Link>
            <Link to={`${designInfo?.headerContactPageLink}`}>
              <img src={"/alps-instagram.svg"} alt="alps instagram" />
            </Link>
          </div>
        </div>
        <div className="w-full flexem px-[56px] sm:px-[20px] py-[21px]">
          {designInfo?.footerAlpsLink === "true" && (
            <Link
              to="https://tryalps.com"
              className="text-[14px] text-[#7C7D7E] cursor-pointer"
            >
              Powered by Alps
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default Footer;
