import Button from "./Button";
import Loader2 from "./Loader/Loader2";

const PlanBillings = ({ setPro }) => {
  return (
    <>
      <div className="w-full h-full cflexss gap-[15px] font-inter">
        <div className="cflexss">
          <p className="font-semibold text-[20px]">Plans & Billings</p>
          <p className="text-[14px] font-medium text-[#7C7D7E]">
            Manage your plans and billings here
          </p>
        </div>
        <div className="w-full rounded-[12px] text-[12px] font-medium px-[22px] py-[17px] flexbm shadow-md border-[1px]">
          <div className="cflexss gap-[9px]">
            <div className="flexsm gap-[14px] text-[#7C7D7E]">
              <p>Current plan</p>
              <div className="bg-[#B4DFBF] flexmm text-[#34A853] rounded-[22px] px-[8px] py-[2px]">
                <p>Free</p>
              </div>
            </div>
            <p className="text-[18px]">Empower users, get PRO now!</p>
          </div>
          <Button
            paddingX="50px"
            paddingY="8px"
            shadow={true}
            handleClick={() => {
              setPro(true);
            }}
          >
            <p>Get Alps Pro</p>
          </Button>
        </div>
        <div className="mt-[30px] w-full text-[#4D4E51] font-medium text-[16px] cflexss gap-[14px]">
          <p>Invoices</p>
          <div className="w-full cflexmm gap-[6px] text-[14px] text-normal">
            <img src="/emptyInvoice.svg" alt="EmptyInvoice" />
            <p>There’s nothing to show here</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanBillings;
