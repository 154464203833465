import React, { useState } from "react";
import { ChevronRight, ChevronDown, Link } from "heroicons-react";
import FileBase64 from "react-file-base64";
import { SketchPicker } from "react-color";

const HeroSection = ({ view, setView, designInfo, setDesignInfo }) => {
  const [viewHero, setViewHero] = useState(false);
  const [logoName, setLogoName] = useState("");
  const [showT, setShowT] = useState(false);
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setDesignInfo({ ...designInfo, [name]: value });
  };

  const OnChangeUploadFile = async (base64) => {
    if (
      base64.type === "image/png" ||
      base64.type === "image/jpg" ||
      base64.type === "image/jpeg" ||
      base64.type === "image/jfif"
    ) {
      setLogoName(base64.name);
      setDesignInfo({ ...designInfo, heroSectionBackground: base64 });
    }
  };
  return (
    <>
      {viewHero ? (
        <>
          <div
            className="w-[85%] flex-shrink bg-[#F5F5F5] border-[2px] rounded-[8px] cflexss"
            onClick={() => {
              setShowT(false);
            }}
          >
            <div
              className={`w-full flexbm px-[14px] py-[12px] rounded-t-[8px] cursor-pointer`}
              onClick={(e) => {
                e.stopPropagation();
                setShowT(false);
                setViewHero(false);
              }}
            >
              <p>Hero section</p>
              <ChevronDown size="24px" />
            </div>
            <div className="w-full bg-white cflexss rounded-b-[8px] text-[#656667] pb-[10px] font-normal text-[14px]">
              <div className="w-full cflexss p-[16px] gap-[16px]">
                <div className="cflexss gap-[6px]">
                  <p className="font-semibold text-[#1F2023]">Headline</p>
                  <p>The heading of your hero section</p>
                </div>
                <input
                  className="w-full shadow-sm rounded-[6px] border-[1px] outline-none px-[12px] py-[8px]"
                  placeholder="Input headline"
                  name="heroSectionHeadline"
                  value={designInfo.heroSectionHeadline}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full cflexss p-[16px] gap-[16px]">
                <div className="cflexss gap-[6px]">
                  <p className="font-semibold text-[#1F2023]">Body copy</p>
                  <p>Write a body text that supports the headline</p>
                </div>
                <textarea
                  className="w-full shadow-sm rounded-[6px] h-[75px] border-[1px] outline-none px-[12px] py-[8px] resize-none"
                  placeholder="Input body copy"
                  name="heroSectionBodyCopy"
                  value={designInfo.heroSectionBodyCopy}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full cflexss p-[16px] gap-[16px]">
                <div className="cflexss gap-[6px]">
                  <p className="font-semibold text-[#1F2023]">Background</p>
                  <p>Select hero background image</p>
                </div>
                <div className="relative rounded-[8px] px-[16px] py-[8px] gap-[8px] flexmm text-[#1F2023] bg-[#E6E6E6] font-medium cursor-pointer">
                  <img src="/cld.svg" alt="uploadedImages" />
                  <p>Upload Image</p>
                  <div className="absolute top-2 left-[-100px] opacity-0">
                    <FileBase64
                      name="headerLogo"
                      defaultValue={designInfo["headerLogo"]}
                      multiple={false}
                      onDone={(base64) => {
                        OnChangeUploadFile(base64);
                      }}
                    />
                  </div>
                </div>
                {logoName && (
                  <p className="text-[12px] text-black">{logoName}</p>
                )}
              </div>

              <div className="relative w-full flexbm p-[16px] pt-[20px] border-b-[1px] gap-[16px]">
                {showT && (
                  <div
                    className="absolute top-[-150px] left-[50px] z-[50]"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <SketchPicker
                      color={designInfo["heroSectionTextColour"]}
                      onChange={(newColor) => {
                        setDesignInfo({
                          ...designInfo,
                          heroSectionTextColour: newColor.hex,
                        });
                      }}
                    />
                  </div>
                )}
                <div className="cflexss gap-[4px]">
                  <p className="font-semibold text-[#1F2023]">Text colour</p>
                  <p>Select hero text colour</p>
                </div>
                <div
                  className="rounded-[6px] p-[8px] flexmm gap-[8px] border-[1px] cursor-pointer"
                  onClick={(e) => {
                    setShowT(true);
                    e.stopPropagation();
                  }}
                >
                  <div
                    className="rounded-[6px] bg-black w-[24px] h-[24px]"
                    style={{ background: designInfo.heroSectionTextColour }}
                  />
                  <ChevronDown size="20px" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`w-[85%] flexbm flex-shrink ${
              view === "Hero section" && "bg-[#F5F5F5]"
            } px-[14px] py-[12px] rounded-[8px] cursor-pointer border-[2px]`}
            onClick={() => {
              setViewHero(true);
              setView("Hero section");
            }}
          >
            <p>Hero section</p>
            <ChevronRight size="24px" />
          </div>
        </>
      )}
    </>
  );
};

export default HeroSection;
