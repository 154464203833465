import React, { useState, useEffect } from "react";
import { Plus, X, TrashOutline } from "heroicons-react";
// import { TrashOutline } from "heroicons-react";
import Button from "./Button";

const ManageLinks = ({ setPopUp, designInfo, setDesignInfo }) => {
  const linkRegex =
    /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+)(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/;
  const [headerLinks, setHeaderLinks] = useState({
    name: "",
    link: "",
  });
  const [hLinks, setHLinks] = useState([]); // [ {name: "name", link: "link"}
  const [error, setError] = useState(false);
  const [addLink, setAddLink] = useState(false);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setHeaderLinks({ ...headerLinks, [name]: value });
  };

  const handleEachChange = (e, index) => {
    let name = e.target.name;
    let value = e.target.value;
    let newLinks = [...hLinks];
    newLinks[index] = { ...newLinks[index], [name]: value };
    setHLinks(newLinks);
  };

  const handleAddLink = (e) => {
    e.preventDefault();
    if (
      headerLinks.name &&
      headerLinks.link &&
      linkRegex.test(headerLinks.link)
    ) {
      setHLinks([...hLinks, headerLinks]);
      setDesignInfo({
        ...designInfo,
        headerLinks: [...hLinks, headerLinks],
      });
      setHeaderLinks({ name: "", link: "" });
      setError(false);
    } else if (hLinks.length > 0) {
      setDesignInfo({
        ...designInfo,
        headerLinks: hLinks,
      });
    }
    if (
      headerLinks.name &&
      headerLinks.link &&
      !linkRegex.test(headerLinks.link)
    ) {
      setError(true);
    } else {
      setError(false);
      setPopUp(false);
    }
  };

  const onDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e, newIndex) => {
    const draggedIndex = e.dataTransfer.getData("index");
    const newItems = [...hLinks];
    const [removed] = newItems.splice(draggedIndex, 1);
    newItems.splice(newIndex, 0, removed);
    setHLinks(newItems);
  };

  useEffect(() => {
    if (designInfo.headerLinks[0]) {
      setHLinks(designInfo.headerLinks);
    } else {
      setDesignInfo({ ...designInfo, headerLinks: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div
        className="fixed top-0 left-0 z-[999] w-full h-full bg-[#000000]/20 flexms pt-[100px]"
        onClick={() => {
          setPopUp(false);
          if (hLinks.length > 0) {
            setDesignInfo({
              ...designInfo,
              headerLinks: hLinks,
            });
          }
        }}
      >
        <div
          className="bg-white rounded-[10px] text-[#656667] text-[14px] p-[24px] shadow-sm"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <form className="cflexss gap-[16px]" onSubmit={handleAddLink}>
            <div className="flexbm w-full">
              <div className="cflexss gap-[6px]">
                <p className="font-semibold text-[18px] text-[#36383A]">
                  Header link
                </p>
                <p>Add and manage links in the header</p>
              </div>
              <X
                size="20px"
                className="cursor-pointer"
                onClick={() => {
                  setPopUp(false);
                  if (hLinks.length > 0) {
                    setDesignInfo({
                      ...designInfo,
                      headerLinks: hLinks,
                    });
                  }
                }}
              />
            </div>
            <div className="cflexss gap-[8px] max-h-[45vh] overflow-y-auto">
              {hLinks.length > 0 && (
                <>
                  {hLinks.map((link, index) => (
                    <div
                      className="flexmm gap-[10px] bg-white"
                      key={index}
                      draggable
                      onDragStart={(e) => onDragStart(e, index)}
                      onDragOver={onDragOver}
                      onDrop={(e) => onDrop(e, index)}
                    >
                      <div className="flexmm text-[#1F2023] font-medium gap-[8px] flex-wrap">
                        <div className="cflexss w-[170px] gap-[8px] flex-shrink">
                          <p>Name</p>
                          <input
                            type="text"
                            name="name"
                            value={link.name}
                            onChange={(e) => {
                              handleEachChange(e, index);
                            }}
                            className="w-full px-[12px] py-[8px] outline-none border-[2px] rounded-[6px]"
                          />
                        </div>
                        <div className="cflexss w-[170px] gap-[8px] flex-shrink">
                          <p>Link</p>
                          <input
                            type="link"
                            name="link"
                            value={link.link}
                            onChange={(e) => {
                              handleEachChange(e, index);
                            }}
                            className="w-full px-[12px] py-[8px] outline-none border-[2px] rounded-[6px]"
                          />
                        </div>
                      </div>
                      <TrashOutline
                        size="25px"
                        className="cursor-pointer mt-[30px]"
                        onClick={() => {
                          let newLinks = [...hLinks];
                          newLinks.splice(index, 1);
                          setHLinks(newLinks);
                          setDesignInfo({
                            ...designInfo,
                            headerLinks: newLinks,
                          });
                        }}
                      />
                      <img
                        src="/move1.svg"
                        alt="move-icon"
                        className="w-[20px] h-[20px] cursor-pointer mt-[30px]"
                        draggable={false}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
            {addLink && (
              <div className="flexmm gap-[10px]">
                <div className="flexmm text-[#1F2023] font-medium gap-[8px] flex-wrap">
                  <div
                    className="cflexss w-[170px] gap-[8px] flex-shrink"
                    draggable={false}
                  >
                    <p>Name</p>
                    <input
                      type="text"
                      name="name"
                      value={headerLinks.name}
                      onChange={handleChange}
                      className="w-full px-[12px] py-[8px] outline-none border-[2px] rounded-[6px]"
                    />
                  </div>
                  <div
                    className="cflexss w-[170px] gap-[8px] flex-shrink"
                    draggable={false}
                  >
                    <p>Link</p>
                    <input
                      type="link"
                      name="link"
                      value={headerLinks.link}
                      onChange={handleChange}
                      className="w-full px-[12px] py-[8px] outline-none border-[2px] rounded-[6px]"
                    />
                  </div>
                </div>
                <TrashOutline
                  size="25px"
                  className="cursor-pointer mt-[30px]"
                  onClick={() => {
                    setHeaderLinks({ name: "", link: "" });
                    setAddLink(false);
                    setError(false);
                  }}
                />
                <img
                  src="/move1.svg"
                  alt="move-icon"
                  className="w-[20px] h-[20px] cursor-pointer mt-[30px]"
                />
              </div>
            )}
            {error && (
              <p className="text-[12px] text-red-600">
                Please provide a valid url!
              </p>
            )}
            <div className="w-full flexem gap-[12px]">
              <button
                className="rounded-[10px] gap-[8px] text-black text-[14px] font-medium flexmm px-[12px] py-[6px] outline-none border-none bg-[#F5F5F5]"
                type="button"
                onClick={() => {
                  setAddLink(true);
                }}
              >
                <Plus size="20px" />
                <p>Add link</p>
              </button>
              <Button gap="8px" type="submit" handleClick={() => {}}>
                <p>Done</p>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ManageLinks;
