import React, { useState, useEffect } from "react";
import { ChevronRight, ChevronDown } from "heroicons-react";

const Fonts = ({ view, setView, designInfo, setDesignInfo}) => {
  const [viewFont, setViewFont] = useState(false);
  const fonts = [
    // Web-Safe Fonts
    "Times New Roman",
    "Georgia",
    "Garamond",
    "Palatino Linotype",
    "Book Antiqua",
    "Arial",
    "Helvetica",
    "Verdana",
    "Trebuchet MS",
    "Gill Sans",
    "Lucida Sans Unicode",
    "Tahoma",
    "Courier New",
    "Lucida Console",
    "Monaco",
    "Comic Sans MS",
    "Impact",
    
    // Google Fonts
    "Roboto",
    "Open Sans",
    "Lato",
    "Montserrat",
    "Oswald",
    "Raleway",
    "Poppins",
    "Source Sans Pro",
    "Merriweather",
    "Ubuntu",
    "Nunito",
    "PT Sans",
    "Playfair Display",
    "Noto Sans",
    "Fira Sans",
    "Josefin Sans",
    "Rubik",
    "Quicksand",
    "Inter",
    "Work Sans",
    
    // Adobe Fonts
    "Proxima Nova",
    "Source Serif Pro",
    "Museo Sans",
    "Avenir Next",
    "Myriad Pro",
    "Univers",
    "Franklin Gothic",
    "Baskerville",
    "Trajan",
    "Minion Pro",
    "Futura",
    "Gill Sans",
    "Garamond"
  ];
  
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value !== "Select font") {
      setDesignInfo({ ...designInfo, [name]: value });      
    }
  };
  return (
    <>
      {viewFont ? (
        <>
          <div className="w-[85%] flex-shrink bg-[#F5F5F5] border-[2px] rounded-[8px] cflexss">
            <div
              className={`w-full flexbm px-[14px] py-[12px] rounded-t-[8px] cursor-pointer`}
              onClick={() => {
                setViewFont(false);
              }}
            >
              <p>Fonts</p>
              <ChevronDown size="24px" />
            </div>
            <div className="w-full bg-white cflexss rounded-b-[8px] text-[#656667] pb-[10px] font-normal text-[14px]">
              <div className="w-full cflexss p-[16px] gap-[16px]">
                <div className="cflexss gap-[6px]">
                  <p className="font-semibold text-[#1F2023]">Heading Font</p>
                  <p>Select fonts to use for all headings on help center.</p>
                </div>
                <select
                  className="w-full shadow-sm rounded-[6px] border-[1px] outline-none px-[12px]  cursor-pointer py-[8px]"
                  name="headingFont"
                  value={designInfo["headingFont"]}
                  onChange={handleChange}
                >
                  <option>Select font</option>
                  {fonts.map((font, index) => {
                    return (
                      <>
                        <option key={index}>{font}</option>
                      </>
                    );
                  })}
                </select>
              </div>

              <div className="w-full cflexss p-[16px] gap-[16px]">
                <div className="cflexss gap-[6px]">
                  <p className="font-semibold text-[#1F2023]">Text Font</p>
                  <p>Select fonts for all body text</p>
                </div>
                <select
                  className="w-full shadow-sm rounded-[6px] border-[1px] outline-none px-[12px]  cursor-pointer py-[8px]"
                  name="textFont"
                  value={designInfo["textFont"]}
                  onChange={handleChange}
                >
                  <option>Select font</option>
                  {fonts.map((font, index) => {
                    return (
                      <>
                        <option key={index} value={font}>
                          {font}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`w-[85%] flexbm flex-shrink ${
              view === "Fonts" && "bg-[#F5F5F5]"
            } px-[14px] py-[12px] rounded-[8px] cursor-pointer border-[2px]`}
            onClick={() => {
              setViewFont(true);
              setView("Fonts");
            }}
          >
            <p>Fonts</p>
            <ChevronRight size="24px" />
          </div>
        </>
      )}
    </>
  );
};

export default Fonts;
