import React, { useState, useEffect } from "react";
import HeroSection from "./HeroSection";
import {
  VideoCameraOutline,
  BookOpenOutline,
  ChevronRight,
  ChevronUp,
  ChevronDown,
} from "heroicons-react";
import CollectionCard from "./CollectionCard";
import {
  getUserCategories,
  getUserPlaylist,
  getCategoryArticles,
  getPlaylistGuides,
} from "../../services/request";
import Loader2 from "../../components/Loader/Loader2";

const Body = ({ designInfo, userProfile }) => {
  const [selectedC, setSelectedC] = useState("");
  const [selectedG, setSelectedG] = useState("");
  const [collectionArticles, setCollectionArticles] = useState([]);
  const [guideVideos, setGuideVideos] = useState([]);
  const [collections, setCollections] = useState([]);
  const [guides, setGuides] = useState([]);
  const [ld, setLd] = useState(false);
  const [ld2, setLd2] = useState(false);

  const getContents = async () => {
    let alpsToken = localStorage.getItem("alpsToken");
    if (!alpsToken) {
      window.location.pathname = "/login";
    }
    let data;
    if (alpsToken) {
      data = await getUserCategories(alpsToken);
      if (data) {
        setCollections(data);
      }
      getPlaylist(alpsToken);
    }
  };

  const getPlaylist = async (alpsToken) => {
    let data;
    data = await getUserPlaylist(alpsToken);
    if (data) {
      setGuides(data);
    }
  };

  const getLinkedArticles = async (id) => {
    setCollectionArticles([]);
    let alpsToken = localStorage.getItem("alpsToken");
    setLd(true);
    let data = await getCategoryArticles(id, alpsToken);
    setCollectionArticles(data);
    setLd(false);
  };

  const getVideos = async (id) => {
    let alpsToken = localStorage.getItem("alpsToken");
    setLd2(true);
    let data = await getPlaylistGuides(id, alpsToken);
    setGuideVideos(data);
    setLd2(false);
  };

  useEffect(() => {
    getContents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [view, setView] = useState("all");
  return (
    <>
      <div
        className="w-full h-full flexbs px-[56px] font-inter py-[51px] gap-[24px] bg-white"
        style={{
          fontFamily: designInfo?.textFont,
        }}
      >
        <div
          className={`w-[280px] cflexss pb-[100px] ${
            userProfile?.profile?.themeType === 2 && "pt-[50px]"
          } gap-[32px] pr-[11px] text-[14px]`}
        >
          <div className="cflexss gap-[12px]">
            <div
              className="font-semibold flexsm gap-[4px] cursor-pointer"
              onClick={() => {
                setView("collections");
              }}
            >
              <BookOpenOutline size="20px" />
              <p>Aritcles</p>
            </div>
            <div
              className="font-semibold flexsm gap-[4px] cursor-pointer"
              onClick={() => {
                setView("guides");
              }}
            >
              <VideoCameraOutline size="20px" />
              <p>Videos</p>
            </div>
          </div>
          <div className="w-full cflexss gap-[2px]">
            <div className="font-bold text-[16px] text-[#4D4E51] flexsm gap-[4px]">
              <BookOpenOutline size="20px" />
              <p>Articles</p>
            </div>
            {collections.map((item, index) => {
              return (
                <>
                  <div
                    key={item.id}
                    className={`w-full flexbm text-[16px] text-[#4D4E51] cursor-pointer px-[16px] py-[12px] ${
                      selectedC === item.name &&
                      "bg-[#f5f5f5] border-l-[2px] border-black"
                    } hover:bg-[#f5f5f5] font-bold`}
                    onClick={() => {
                      if (selectedC === item.name) {
                        setSelectedC("");
                      } else {
                        getLinkedArticles(item.id);
                        setSelectedC(item.name);
                      }
                    }}
                  >
                    <p className="line-clamp-1">{item.name}</p>
                    {selectedC === item.name ? (
                      <ChevronUp size="20px" />
                    ) : (
                      <ChevronDown size="20px" />
                    )}
                  </div>
                  <ul
                    key={index}
                    className="w-full cflexss gap-[7px] text-[14px] font-medium border-l-[2px] text-[#656667] ml-[10px] list-none"
                  >
                    {selectedC === item.name && (
                      <>
                        {ld ? (
                          <Loader2 />
                        ) : (
                          <>
                            {collectionArticles.map((article, index) => {
                              return (
                                <>
                                  {article.status === "published" && (
                                    <li
                                      className="cursor-pointer line-clamp-1 w-full px-[10px] py-[8px] rounded-[8px] hover:bg-[#f5f5f5]"
                                      key={article.id}
                                    >
                                      {article.title}
                                    </li>
                                  )}
                                </>
                              );
                            })}
                          </>
                        )}
                      </>
                    )}
                  </ul>
                </>
              );
            })}
          </div>
          <div className="w-full cflexss gap-[2px]">
            <div className="font-bold text-[16px] text-[#4D4E51] flexsm gap-[4px]">
              <VideoCameraOutline size="20px" />
              <p>Videos</p>
            </div>
            {guides?.map((item, index) => {
              return (
                <>
                  {item.status === "published" && (
                    <>
                      <div
                        key={item.id}
                        className={`w-full flexbm text-[16px] text-[#4D4E51] cursor-pointer px-[16px] py-[12px] ${
                          selectedG === item.name &&
                          "bg-[#f5f5f5] border-l-[2px] border-black"
                        } hover:bg-[#f5f5f5] font-bold`}
                        onClick={() => {
                          if (selectedG === item.name) {
                            setSelectedG("");
                          } else {
                            getVideos(item.id);
                            setSelectedG(item.name);
                          }
                        }}
                      >
                        <p className="line-clamp-1">{item.name}</p>
                        {selectedG === item.name ? (
                          <ChevronUp size="20px" />
                        ) : (
                          <ChevronDown size="20px" />
                        )}
                      </div>
                      <ul
                        key={index}
                        className="w-full cflexss gap-[7px] text-[14px] font-medium border-l-[2px] text-[#656667] ml-[10px] list-none"
                      >
                        {selectedG === item.name && (
                          <>
                            {ld2 ? (
                              <Loader2 />
                            ) : (
                              guideVideos.map((article, index) => {
                                return (
                                  <li
                                    className="cursor-pointer line-clamp-1 w-full px-[10px] py-[8px] rounded-[8px] hover:bg-[#f5f5f5]"
                                    key={article.id}
                                  >
                                    {article.title}
                                  </li>
                                );
                              })
                            )}
                          </>
                        )}
                      </ul>
                    </>
                  )}
                </>
              );
            })}
          </div>
        </div>
        <div className="w-full h-full cflexss gap-[150px]">
          {userProfile?.profile?.themeType === 2 && (
            <HeroSection
              designInfo={designInfo}
              userProfile={userProfile}
              background={false}
            />
          )}
          {(view === "all" || view === "collections") && (
            <div
              className={`w-full h-full ${
                userProfile?.profile?.themeType === 2 && "mt-[-120px]"
              } cflexss gap-[50px]`}
            >
              <div className="w-full flexbm">
                <div className="cflexss gap-[8px]">
                  <p className="text-[36px] font-bold">Collections</p>
                  <p className="text-[16px] text-[#666666]">
                    Level up your docs and your skills with our libary of guides
                  </p>
                </div>
                <button className="px-[17px] py-[8px] rounded-[10px] border-[2px] text-[16px] font-semibold">
                  <p>See more</p>
                </button>
              </div>
              <div className="w-full flexss gap-[20px] flex-wrap">
                {collections?.map((item, index) => {
                  return (
                    <>
                      {index + 1 < 7 && (
                        <CollectionCard
                          item={item}
                          type="collection"
                          designInfo={designInfo}
                          key={item.id}
                        />
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          )}

          {(view === "all" || view === "guides") && (
            <div className="w-full h-full cflexss gap-[50px]">
              <div className="w-full flexbm">
                <div className="cflexss gap-[8px]">
                  <p className="text-[36px] font-bold">Guides</p>
                  <p className="text-[16px] text-[#666666]">
                    Level up your docs and your skills with our libary of guides
                  </p>
                </div>
                <button className="px-[17px] py-[8px] rounded-[10px] border-[2px] text-[16px] font-semibold">
                  <p>See more</p>
                </button>
              </div>
              <div className="w-full flexss gap-[20px] flex-wrap">
                {guides?.map((item, index) => {
                  return (
                    <>
                      {item.status === "published" && (
                        <>
                          {index + 1 < 7 && (
                            <CollectionCard
                              item={item}
                              type="guide"
                              designInfo={designInfo}
                              key={item.id}
                            />
                          )}
                        </>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Body;
