import React from "react";
import Header from "./LandingPage/Header";
import Footer from "./LandingPage/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="w-full flexmm mt-[140px] mb-[80px] font-inter">
        <div className="w-[677px] md:w-full md:px-[20px] cflexsm gap-[27px]">
          <div className="cflexmm gap-[10px] text-center">
            <p className="font-semibold text-[40px] md:text-[35px]">
              Privacy Policy
            </p>
            <p className="text-[12px]">Last updated June 25th 2024</p>
          </div>
          <div className="w-full cflexss gap-[10px] text-[16px] text-[#4D4E51]">
            <h1 className="font-semibold text-[24px] md:text-[25px] text-[#000000]">
              1. Introduction
            </h1>
            <p>
              Welcome to Alps ("we," "our," or "us"). We are committed to
              protecting your privacy and ensuring that your personal
              information is handled in a safe and responsible manner. This
              Privacy Policy explains how we collect, use, disclose, and protect
              your information when you use our knowledge-based web application
              targeting companies in Africa. Alps provides comprehensive guides
              and help center to support your business success.
            </p>
          </div>
          <div className="w-full cflexss gap-[10px] text-[16px] text-[#4D4E51]">
            <h1 className="font-semibold text-[24px] md:text-[25px] text-[#000000]">
              2. Information We Collect
            </h1>
            <p>
              We collect various types of information in connection with the
              services we provide, including:
            </p>
          </div>
          <div className="w-full cflexss gap-[10px] text-[16px] text-[#4D4E51]">
            <h1 className="font-semibold text-[24px] md:text-[25px] text-[#000000]">
              2.1. Personal Information
            </h1>
            <div className="w-full cflexss gap-[5px] md:gap-[10px]">
              <p>
                <span className="font-medium">Contact Information: </span>
                First and last names, profile picture, email address, phone
                number, and company name.
              </p>
              <p>
                <span className="font-medium">Account Information: </span>
                Username, password, and profile picture.
              </p>
              <p>
                <span className="font-medium">Payment Information: </span>
                Payment details necessary for processing transactions.
              </p>
              <p>
                <span className="font-medium">Google Account: </span>
                When you choose to sign in using your Google account, we collect
                and use your Google full name and email address to associate it
                with your Alps account. This allows us to personalize your
                experience and facilitate seamless authentication without
                requiring you to create a separate username and password for our
                services. We will not access, store, or use any other
                information from your Google account unless explicitly
                authorized by you.
              </p>
            </div>
          </div>
          <div className="w-full cflexss gap-[10px] text-[16px] text-[#4D4E51]">
            <h1 className="font-semibold text-[24px] md:text-[25px] text-[#000000]">
              2.2. Usage Information
            </h1>
            <div className="w-full cflexss gap-[10px]">
              <p>
                <span className="font-medium">Technical Information: </span>
                IP address, browser type, operating system, device type, and
                access times.
              </p>
              <p>
                <span className="font-medium"> Usage Data: </span>
                Pages visited, time spent on pages, links clicked, and other
                usage statistics.
              </p>
            </div>
          </div>
          <div className="w-full cflexss gap-[10px] text-[16px] text-[#4D4E51]">
            <h1 className="font-semibold text-[24px] md:text-[25px] text-[#000000]">
              2.3. Communication Information
            </h1>
            <div className="w-full cflexss gap-[10px]">
              <p>
                <span className="font-medium"> Customer Support: </span>
                Information you provide when you contact us for help, such as
                your contact details and the content of your communications.
              </p>
            </div>
          </div>
          <div className="w-full cflexss gap-[10px] text-[16px] text-[#4D4E51]">
            <h1 className="font-semibold text-[24px] md:text-[25px] text-[#000000]">
              2.4. Cookies and Tracking Technologies
            </h1>
            <div className="w-full cflexss gap-[10px]">
              <p>
                <span className="font-medium"> Cookies: </span>
                Small data files stored on your device that help us improve our
                services and your experience.
              </p>
              <p>
                Electronic images used to count users who have visited certain
                pages or viewed emails.
              </p>
              <p>
                <span className="font-medium"> Microsoft Clarity: </span>
                We partner with Microsoft Clarity and Microsoft Advertising to
                capture how you use and interact with our website through
                behavioral metrics, heatmaps, and session replay to improve your
                experience with our products/services. Website usage data is
                captured using first and third-party cookies and other tracking
                technologies to determine the popularity of products/services
                and online activity. Additionally, we use this information for
                site optimization, fraud/security purposes, and advertising. For
                more information about how Microsoft collects and uses your
                data, visit the{" "}
                <a
                  href="https://privacy.microsoft.com/en-us/privacystatement"
                  className="font-medium"
                >
                  {" "}
                  Microsoft Privacy Statement{" "}
                </a>
                .
              </p>
            </div>
          </div>

          <div className="w-full cflexss gap-[10px] text-[16px] text-[#4D4E51]">
            <h1 className="font-semibold text-[24px] md:text-[25px] text-[#000000]">
              3. How We Use Your Information
            </h1>
            <p>
              We use the information we collect for various purposes, including:
            </p>
            <div className="w-full cflexss mt-[10px] gap-[10px]">
              <ul className="pl-[20px] cflexss gap-[10px] list-disc">
                <li>
                  To Provide and Improve Services: Delivering, maintaining, and
                  improving our web application and services, including
                  comprehensive guides, tutorials, and technical documentation.
                </li>
                <li>
                  To Personalize User Experience: Customizing content,
                  recommendations, and advertisements to better suit your needs.
                </li>
                <li>
                  To Facilitate Payments: Processing transactions and managing
                  billing information.
                </li>
                <li>
                  To Communicate with You: Sending updates, newsletters,
                  marketing communications, and responding to your inquiries.
                </li>
                <li>
                  To Analyze and Improve: Conducting analytics to understand
                  usage patterns and improve user experience.
                </li>
                <li>
                  To Ensure Security: Detecting, preventing, and responding to
                  fraud, abuse, security risks, and technical issues.
                </li>
                <li>
                  To Comply with Legal Obligations: Fulfilling our legal
                  obligations, resolving disputes, and enforcing our agreements.
                </li>
              </ul>
            </div>
          </div>

          <div className="w-full cflexss gap-[10px] text-[16px] text-[#4D4E51]">
            <h1 className="font-semibold text-[24px] md:text-[25px] text-[#000000]">
              4. How We Share Your Information
            </h1>
            <div className="w-full cflexss gap-[10px]">
              <ul className="pl-[20px] cflexss gap-[10px] list-disc">
                <li>
                  With Service Providers: Third-party vendors who assist us in
                  providing our services, such as hosting, data analysis,
                  payment processing, and customer service.
                </li>
                <li>
                  With Affiliates: Our corporate affiliates for purposes
                  consistent with this Privacy Policy.
                </li>
                <li>
                  In Business Transfers: In connection with any merger, sale, or
                  transfer of company assets, your information may be
                  transferred.
                </li>
                <li>
                  For Legal Reasons: When required by law or in response to
                  legal process, such as a subpoena or court order.
                </li>
                <li>
                  With Your Consent: When you have given us explicit consent to
                  share your information.
                </li>
              </ul>
            </div>
          </div>

          <div className="w-full cflexss gap-[10px] text-[16px] text-[#4D4E51]">
            <h1 className="font-semibold text-[24px] md:text-[25px] text-[#000000]">
              5. Your Choices
            </h1>
            <p>You have several options regarding your information:</p>
            <div className="w-full cflexss gap-[10px]">
              <ul className="pl-[20px] cflexss gap-[10px] list-disc">
                <li>
                  Access and Update: You can access and update your account
                  information by logging into your account.
                </li>
                <li>
                  Opt-Out: You can opt-out of receiving marketing communications
                  by following the unsubscribe instructions in those emails.
                </li>
                <li>
                  Cookie Settings: You can manage your cookie preferences
                  through your browser settings.
                </li>
              </ul>
            </div>
          </div>

          <div className="w-full cflexss gap-[10px] text-[16px] text-[#4D4E51]">
            <h1 className="font-semibold text-[24px] md:text-[25px] text-[#000000]">
              6. Security
            </h1>
            <p>
              We implement appropriate technical and organizational measures to
              protect your information from unauthorized access, loss, misuse,
              or alteration. However, no method of transmission over the
              internet or electronic storage is 100% secure.
            </p>
          </div>

          <div className="w-full cflexss gap-[10px] text-[16px] text-[#4D4E51]">
            <h1 className="font-semibold text-[24px] md:text-[25px] text-[#000000]">
              7. Data Retention
            </h1>
            <p>
              We retain your personal information for as long as necessary to
              fulfill the purposes outlined in this Privacy Policy unless a
              longer retention period is required or permitted by law.
            </p>
          </div>

          <div className="w-full cflexss gap-[10px] text-[16px] text-[#4D4E51]">
            <h1 className="font-semibold text-[24px] md:text-[25px] text-[#000000]">
              8. International Data Transfers
            </h1>
            <p>
              Your information may be transferred to and processed in countries
              other than your own. These countries may have data protection laws
              that are different from those of your country. We take appropriate
              measures to ensure that your information remains protected.
            </p>
          </div>

          <div className="w-full cflexss gap-[10px] text-[16px] text-[#4D4E51]">
            <h1 className="font-semibold text-[24px] md:text-[25px] text-[#000000]">
              9. Children's Privacy
            </h1>
            <p>
              Our services are not directed to children under 13, and we do not
              knowingly collect personal information from children under 13. If
              we become aware that we have collected personal information from a
              child under 13, we will take steps to delete such information.
            </p>
          </div>

          <div className="w-full cflexss gap-[10px] text-[16px] text-[#4D4E51]">
            <h1 className="font-semibold text-[24px] md:text-[25px] text-[#000000]">
              10. Changes to This Privacy Policy
            </h1>
            <p>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on our
              website. Your continued use of our services after any changes take
              effect will constitute your acceptance of the revised policy.
            </p>
          </div>

          <div className="w-full cflexss gap-[10px] text-[16px] text-[#4D4E51]">
            <h1 className="font-semibold text-[24px] md:text-[25px] text-[#000000]">
              Contact Us
            </h1>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us at: thealpshq@gmail.com
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
