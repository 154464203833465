import React, { useState } from "react";
import { X, EyeOffOutline, EyeOutline } from "heroicons-react";
import { updatePassword } from "../services/request";
import Loader from "./Loader/Loader";
import Button from "./Button";

const UpdatePassword = ({ setUpdate, setPopUp, setModal }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [passwordDetails, setPasswordDetails] = useState({
    currentPassword: "",
    newPassword: "",
    newConfirmPassword: "",
  });

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordDetails({ ...passwordDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let alpsToken = localStorage.getItem("alpsToken");
    if (
      passwordDetails.newPassword &&
      passwordDetails.currentPassword &&
      passwordDetails.newConfirmPassword &&
      passwordDetails.newPassword === passwordDetails.newConfirmPassword &&
      passwordDetails.newPassword.length >= 12
    ) {
      setErr(false);
      setLoading(true);
      await updatePassword(
        alpsToken,
        passwordDetails,
        setPopUp,
        setModal,
        setUpdate
      );
      setLoading(false);
    }
    if (
      passwordDetails.newPassword !== passwordDetails.newConfirmPassword ||
      passwordDetails.newPassword.length < 12
    ) {
      setErr(true);
    }
  };
  return (
    <>
      <div
        className="fixed top-0 left-0 w-full h-full bg-[#000000]/30 flexmm z-[150] font-inter"
        onClick={() => {
          setUpdate(false);
        }}
      >
        <form
          className="rounded-[6px] cflexss gap-[32px] p-[20px] bg-white shadow-xl"
          onSubmit={handleSubmit}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="w-full flexbm font-semibold text-[18px]">
            <p>Set new password</p>
            <X
              size="20px"
              className="cursor-pointer"
              onClick={() => {
                setUpdate(false);
              }}
            />
          </div>
          <div className="w-full cflexss text-[12px] gap-[19px]">
            <div className="cflexss gap-[8px]">
              <p>Type current password</p>
              <div className="w-[260px] border-[2px] px-[12px] rounded-[4px] gap-[10px] flexbm">
                <input
                  placeholder="Current password"
                  name="currentPassword"
                  value={passwordDetails.currentPassword}
                  type={showCurrentPassword ? "text" : "password"}
                  onChange={handleChange}
                  className="w-full border-none outline-none py-[8px]"
                />
                {showCurrentPassword ? (
                  <EyeOffOutline
                    size="20px"
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    className="cursor-pointer"
                  />
                ) : (
                  <EyeOutline
                    size="20px"
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    className="cursor-pointer"
                  />
                )}
              </div>
            </div>
            <div className="cflexss gap-[8px]">
              <p>Type new password</p>
              <div className="w-[260px] border-[2px] px-[12px] rounded-[4px] gap-[10px] flexbm">
                <input
                  placeholder="New password"
                  name="newPassword"
                  value={passwordDetails.newPassword}
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  className="w-full border-none outline-none py-[8px]"
                />
                {showPassword ? (
                  <EyeOffOutline
                    size="20px"
                    onClick={() => setShowPassword(!showPassword)}
                    className="cursor-pointer"
                  />
                ) : (
                  <EyeOutline
                    size="20px"
                    onClick={() => setShowPassword(!showPassword)}
                    className="cursor-pointer"
                  />
                )}
              </div>
            </div>
            <div className="cflexss gap-[8px]">
              <p>Confirm new password</p>
              <div className="w-[260px] border-[2px] px-[12px] rounded-[4px] gap-[10px] flexbm">
                <input
                  placeholder="Confirm new password"
                  name="newConfirmPassword"
                  value={passwordDetails.newConfirmPassword}
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={handleChange}
                  className="w-full border-none outline-none py-[8px]"
                />
                {showConfirmPassword ? (
                  <EyeOffOutline
                    size="20px"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="cursor-pointer"
                  />
                ) : (
                  <EyeOutline
                    size="20px"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="cursor-pointer"
                  />
                )}
              </div>
            </div>
            {err && (
              <p className="w-[260px] text-red-500 text-[12px]">
                Passwords do not match or password is less than 12 characters
              </p>
            )}
          </div>
          <div className="w-full flexem">
            <Button type="submit" handleClick={() => {}}>
              {loading ? (
                <div className="flexmm gap-[10px]">
                  <Loader />
                  <p>Set new password</p>
                </div>
              ) : (
                <p>Set new password</p>
              )}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdatePassword;
