import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import Loader2 from "../components/Loader/Loader2";
import TermsPrivacy from "./collections/TermsPrivacy";
import { EyeOutline, EyeOffOutline } from "heroicons-react";
import { resetPassword, loginWithToken } from "../services/request";

const Reset = () => {
  const [registerDetails, setRegisterDetails] = useState({
    password: "",
    confirmPassword: "",
    email: "",
  });
  const [skip, setSkip] = useState(false);
  const [token, setToken] = useState("");
  const [changing, setChanging] = useState(false);
  const [err, setErr] = useState(false);
  const [showP, setShowP] = useState(false);
  const [showNewP, setShowNewP] = useState(false);
  const [match, setMatch] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setRegisterDetails({ ...registerDetails, [name]: value });
    setChanging(!changing);
  };

  useEffect(() => {
    if (
      registerDetails["password"] &&
      registerDetails["password"].length >= 12
    ) {
      setErr(false);
    } else if (registerDetails["password"].length === 0) {
      setErr(false);
    } else if (registerDetails["password"].length < 12) {
      setErr(true);
    }

    if (
      registerDetails["password"].length > 0 &&
      registerDetails["password"] !== registerDetails["confirmPassword"]
    ) {
      setMatch(false);
    } else if (
      registerDetails["password"] === registerDetails["confirmPassword"]
    ) {
      setMatch(true);
    }
  }, [changing]);

  const getUrlData = () => {
    const url = window.location.href;
    // Extract the query string from the URL
    const queryString = url.split("?")[1];

    // Extract the token and email using regular expressions
    const tokenRegex = /token=([^&]+)/;
    const emailRegex = /email=([^&]+)/;

    const tokenMatch = queryString.match(tokenRegex);
    const emailMatch = queryString.match(emailRegex);

    const token = tokenMatch ? decodeURIComponent(tokenMatch[1]) : null;
    const email = emailMatch ? decodeURIComponent(emailMatch[1]) : null;
    setRegisterDetails({ ...registerDetails, email });
    setToken(token);
  };

  const resetUserPassword = async () => {
    setLoading(true);
    await resetPassword(registerDetails, token);
    setLoading(false);
  };

  const handeSubmit = (e) => {
    e.preventDefault();
    if (!err && match && token && registerDetails["email"]) {
      resetUserPassword();
    }
  };

  const handleSkipToHome = async () => {
    setSkip(true);
    await loginWithToken({ email: registerDetails["email"] }, token);
    setSkip(false);
  };

  useEffect(() => {
    getUrlData();
  }, []);
  return (
    <>
      <div className="w-full h-[100vh] cflexbm text-[16px] pt-[32px] pb-[50px] font-inter">
        <Link to="/" className="w-[85px] h-[85px] flexmm shadow-sm">
          <img src="/logoAlps.svg" className="w-full h-full" alt="logo" />
        </Link>
        <form
          className="m-[10px] py-[50px] px-[40px] w-[400px] cflexsm flex-shrink pt-[64px] pb-[70px]"
          onSubmit={handeSubmit}
        >
          <p className="text-[29.63px] font-[700] text-center leading-[48px] pb-[24px] text-[#2D333A]">
            Reset your password
          </p>
          <div className="border-[2px] shadow-sm w-full border-b-black p-[10px] mb-[15px] rounded-[8px] flexmm gap-[10px]">
            <input
              type={showP ? "text" : "password"}
              name="password"
              value={registerDetails.password}
              onChange={handleChange}
              placeholder="Type new password"
              className="border-none w-full outline-none"
            />
            {showP ? (
              <EyeOffOutline
                size="20px"
                onClick={() => setShowP(!showP)}
                className="cursor-pointer"
              />
            ) : (
              <EyeOutline
                size="20px"
                onClick={() => setShowP(!showP)}
                className="cursor-pointer"
              />
            )}
          </div>
          <div className="border-[2px] shadow-sm w-full border-b-black p-[10px] mb-[15px] rounded-[8px] flexmm gap-[10px]">
            <input
              type={showNewP ? "text" : "password"}
              name="confirmPassword"
              value={registerDetails.confirmPassword}
              onChange={handleChange}
              placeholder="Re-type new password"
              className=" border-none w-full outline-none"
            />
            {showNewP ? (
              <EyeOffOutline
                size="20px"
                onClick={() => setShowNewP(!showNewP)}
                className="cursor-pointer"
              />
            ) : (
              <EyeOutline
                size="20px"
                onClick={() => setShowNewP(!showNewP)}
                className="cursor-pointer"
              />
            )}
          </div>

          {err && (
            <div className="w-full rounded-[4px] text-[14px] p-[10px] mb-[15px] border-[2px]">
              <p>Your password must contain:</p>
              <ul
                className="px-[20px] font-[400] leading-[22px]"
                style={{ listStyleType: "unset" }}
              >
                <li>At least 12 characters</li>
              </ul>
            </div>
          )}
          {!match && (
            <div className="w-full rounded-[4px] text-[14px] p-[10px] mb-[15px] border-[2px]">
              <p>Password does not match</p>
            </div>
          )}
          <div className="w-full flexem gap-[15px] mb-[24px]">
            <div              
              className="text-[16px] font-[600] border-none px-[17px] py-[8px] flexmm cursor-pointer"
              onClick={handleSkipToHome}
            >
              {skip ? (
                <div className="flexmm gap-[10px]">
                  <Loader2 />
                  <p className="leading-[22px]">Skip to home</p>
                </div>
              ) : (
                <p className="leading-[22px]">Skip to home</p>
              )}
            </div>
            <button
              type="submit"
              className="text-[16px] text-white font-[600] border-none px-[17px] py-[8px] flexmm rounded-[10px] bg-buttonColor"
            >
              {loading ? (
                <Loader />
              ) : (
                <p className="leading-[22px]">Continue</p>
              )}
            </button>
          </div>
          <div className="relative pb-[30px] mb-[24px] w-full flexmm">
            <p>
              Go back to{" "}
              <Link to="/login" className="underline">
                {" "}
                Log in
              </Link>
            </p>
          </div>
        </form>
        <TermsPrivacy />
      </div>
    </>
  );
};

export default Reset;
