import React, { useState, useEffect } from "react";
import NavBar from "../collections/NavBar";
import { CheckCircleOutline } from "heroicons-react";
import Button from "../../components/Button";

const ReportsContent = () => {
  const [bugs, setBugs] = useState([
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
  ]);
  const [features, setFeatures] = useState([
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
  ]);
  const [questions, setQuestions] = useState([
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "Old",
    },
    {
      title: "Not reflecting correct invoicing",
      description: "This help center article provides a step-by-step",
      day: "13/01/24",
      state: "New",
    },
  ]);
  const [searchQuery, setSearchQuery] = useState("");
  const [view, setView] = useState("");
  const [formType, setFormType] = useState("Bugs");

  const filteredBugs = bugs?.filter((bug) =>
    bug.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredFeatures = features?.filter((feature) =>
    feature.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredQuestions = questions?.filter((question) =>
    question.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <>
      <NavBar active="Reports" />
      <div className={`w-full flexmm h-full font-inter mt-[250px]`}>
        <div
          style={{ height: "calc(100vh - 95px)", width: "calc(100vw - 330px)" }}
        >
          {view === "" && (
            <>
              <div className="w-full cflexss gap-[10px] px-[124px] py-[38px] text-[#36383A]">
                <div className="w-full cflexss gap-[15px]">
                  <div className="flexbm w-full">
                    <div className="flexsm gap-[8px] text-[16px]">
                      <div className="font-medium flexmm bg-[#9747FF] text-white px-[13px] py-[6px]">
                        <p>{formType}</p>
                      </div>
                      <p className="font-normal">July 10, 2024</p>
                    </div>
                    <div className="flexem gap-[16px] text-[14px]">
                      <button className="px-[12px] py-[6px] flexmm gap-[8px] bg-[#F5F5F5] rounded-[10px]">
                        <CheckCircleOutline size="20px" />
                        <p>Mark as resolved</p>
                      </button>
                      <Button
                        paddingX="12px"
                        paddingY="6px"
                        gap="12px"
                        rounded="10px"
                      >
                        <p>Respond via email</p>
                      </Button>
                    </div>
                  </div>
                  <p className="text-[32px] font-medium text-[#36383A]">
                    This is what a subject of the support looks like
                  </p>
                </div>
                <div className="cflexss gap-[49px]">
                  <div className="cflexss gap-[11px] text-[16px] font-normal">
                    <p className="text-[18px] font-semibold">Message</p>
                    <div className="cflexss">
                      <p>Hi Team,</p>
                      <p>
                        This help center article provides a step-by-step guide
                        on performing common file operations using the command
                        line interface (CLI). Users will learn essential
                        commands. This help center article provides a
                        step-by-step guide on performing common file operations
                        using the command line interface (CLI). Users will learn
                        essential commands
                      </p>
                    </div>
                  </div>
                  <div className="clfexss gap-[11px] text-[16px] font-normal">
                    <p className="text-[18px] font-semibold">Attachment(s)</p>
                    <p className="text-[#1072E5] cursor-pointer">
                      Payment Receipt.pdf
                    </p>
                    <p className="text-[#1072E5] cursor-pointer">
                      Payment Receipt.pdf
                    </p>
                    <p className="text-[#1072E5] cursor-pointer">
                      Payment Receipt.pdf
                    </p>
                    <p className="text-[#1072E5] cursor-pointer">
                      Payment Receipt.pdf
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ReportsContent;
