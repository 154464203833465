import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader2 from "./Loader/Loader2";
import { Search } from "heroicons-react";
import {
  getUserPlaylist,
  getAllArticles,
  getUserCategories,
} from "../services/request";

const SearchModal = ({ setIsOpenModal }) => {
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [playlist, setPlaylist] = useState([]);

  const getSearched = async (e) => {
    e?.preventDefault();
    let alpsToken = localStorage.getItem("alpsToken");
    if (alpsToken) {
      // setSearched(false);
      setArticles([]);
      setCategories([]);
      setPlaylist([]);
      setLoading(true);
      // let data1 = await searchCategories(
      //   alpsToken,
      //   searchQuery.replace(/ /g, "%20")
      // );
      let data1 = await getUserCategories(alpsToken);
      if (data1) {
        setCategories(data1);
      }
      let data2 = await getUserPlaylist(alpsToken);
      if (data2) {
        setPlaylist(data2);
      }
      let data3 = await getAllArticles(alpsToken);
      if (data3) {
        setArticles(data3);
      }
      // setSearched(true);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (searchQuery) {
  //     setSearched(true);
  //   } else if (searchQuery === "") {
  //     setSearched(false);
  //   }
  // }, [searchQuery]);

  useEffect(() => {
    searchRef.current.focus();
    getSearched();
  }, []);

  const filteredArticles = articles?.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredCategories = categories?.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredPlaylist = playlist?.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <>
      <div
        className="fixed z-[100] top-0 right-0 w-full h-[100vh] flexmm bg-secondary3 font-inter"
        onClick={() => {
          setIsOpenModal(false);
        }}
      >
        <div
          className="flexss z-[150] w-[750px] flex-shrink pt-[15px] rounded-[6px] px-[16px] bg-white shadow-xl"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <form
            className="relative cflexss w-full gap-[38px]"
            onSubmit={getSearched}
          >
            <div className="cflexss w-full gap-auto overflow-y-auto min-h-[250px] max-h-[65vh]">
              {filteredArticles.length === 0 &&
                filteredCategories.length === 0 &&
                filteredPlaylist.length === 0 &&
                searchQuery && (
                  <>
                    <div className="w-full h-full py-[20px] flexmm font-bold text-black/70">
                      <p>No Data Found</p>
                    </div>
                  </>
                )}
              <div
                className={`${
                  filteredArticles.length > 0 ? "block" : "hidden"
                } w-full cflexss gap-[7px]`}
              >
                {searchQuery && filteredArticles.length > 0 && !loading && (
                  <>
                    <div className="w-full border-b-[2px] pb-[4px] text-[20px] font-medium text-[#7C7D7E] leading-[22px]">
                      <p>Articles</p>
                    </div>
                    <div className="w-full cflexss">
                      {filteredArticles.map((item, index) => {
                        return (
                          <>
                            <div
                              className="w-full flexbm font-medium text-[14px] cursor-pointer rounded-[10px] p-[10px] hover:bg-gray-200"
                              key={index}
                              onClick={() => {
                                navigate(`/articles/${item.id}`);
                                setIsOpenModal(false);
                              }}
                            >
                              <p className="flexss gap-[15px]">
                                {item.title}
                                <span className="text-[#7C7D7E] line-clamp-1">
                                  {item.description}
                                </span>
                              </p>
                              <div className="flexsm gap-[8px] w-[100px]">
                                <div
                                  className={`w-[8px] h-[8px] rounded-full ${
                                    item.status === "published" &&
                                    "bg-[#22C55E]"
                                  } ${
                                    item.status === "draft" && "bg-[#FBBF24]"
                                  } ${
                                    item.status === "archived" && "bg-[#EF4444]"
                                  }`}
                                />
                                <p className="capitalize">{item.status}</p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>

              <div
                className={`${
                  filteredCategories.length > 0 ? "block" : "hidden"
                } w-full cflexss gap-[7px]`}
              >
                {searchQuery && filteredCategories.length > 0 && !loading && (
                  <>
                    <div className="w-full border-b-[2px] pb-[4px] text-[20px] font-medium text-[#7C7D7E] leading-[22px]">
                      <p>Categories</p>
                    </div>
                    <div className="w-full cflexss">
                      {filteredCategories.map((item, index) => {
                        return (
                          <>
                            <div
                              className="w-full flexbm font-medium text-[14px] cursor-pointer rounded-[10px] p-[10px] hover:bg-gray-200"
                              key={index}
                              onClick={() => {
                                navigate(`/collections/${item.id}`);
                                setIsOpenModal(false);
                              }}
                            >
                              <p className="flexss gap-[15px]">
                                {item.name}
                                <span className="text-[#7C7D7E] line-clamp-1">
                                  {item.description}
                                </span>
                              </p>
                              <div className="flexsm gap-[8px] w-[100px]">
                                <div
                                  className={`w-[8px] h-[8px] rounded-full ${
                                    item.status === "published" &&
                                    "bg-[#22C55E]"
                                  } ${
                                    item.status === "draft" && "bg-[#FBBF24]"
                                  } ${
                                    item.status === "archived" && "bg-[#EF4444]"
                                  }`}
                                />
                                <p className="capitalize">{item.status}</p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>

              <div className={`${
                  filteredPlaylist.length > 0 ? "block" : "hidden"
                } w-full cflexss gap-[7px]`}>
                {searchQuery && filteredPlaylist.length > 0 && !loading && (
                  <>
                    <div className="w-full border-b-[2px] pb-[4px] text-[20px] font-medium text-[#7C7D7E] leading-[22px]">
                      <p>Guides & Videos</p>
                    </div>
                    <div className="w-full cflexss">
                      {filteredPlaylist.map((item, index) => {
                        return (
                          <>
                            <div
                              className="w-full flexbm font-medium text-[14px] cursor-pointer rounded-[10px] p-[10px] hover:bg-gray-200"
                              key={index}
                              onClick={() => {
                                navigate(`/guides/${item.id}`);
                                setIsOpenModal(false);
                              }}
                            >
                              <p className="flexss gap-[15px]" > 
                                {item.name}{" "}
                                <span className="text-[#7C7D7E] truncate">
                                  {item.description}
                                </span>
                              </p>
                              <div className="flexsm gap-[8px] w-[100px]">
                                <div
                                  className={`w-[8px] h-[8px] rounded-full ${
                                    item.status === "published" &&
                                    "bg-[#22C55E]"
                                  } ${
                                    item.status === "draft" && "bg-[#FBBF24]"
                                  } ${
                                    item.status === "archived" && "bg-[#EF4444]"
                                  }`}
                                />
                                <p className="capitalize">{item.status}</p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="w-full flexsm border-t-[2px]">
              <button className="outline-none border-none flexmm" type="submit">
                {loading ? (
                  <Loader2 />
                ) : (
                  <Search
                    size="20px"
                    color="#C6C6C6"
                    className="cursor-pointer"
                    onClick={getSearched}
                  />
                )}
              </button>
              <input
                ref={searchRef}
                type="search"
                placeholder="Search for Articles, Collections, Guides"
                className="w-full outline-none p-[16px]"
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SearchModal;
