import React, { useState, useEffect, Fragment } from "react";
import ReactPaginate from "react-paginate";
import { ArrowLeft, ArrowRight, Plus } from "heroicons-react";
import { Paginated, GetPaginatedData } from "../../atomicComponents/Pagination";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "../../../node_modules/react-loading-skeleton/dist/skeleton.css";
// import MoveArticle from "../../components/MoveArticle";
import Button from "../../components/Button";
import { reorderArticle } from "../../services/request";

const EachArticle = ({
  articles,
  setArticles,
  setArticle,
  setContent,
  title,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <>
      {isOpenModal && modalContent}
      <div className="cflexss w-full gap-[42px] pt-[40px] px-[10%] font-inter">
        <div className="w-full border-b-[2px] text-[28px] pb-[10px] font-medium flexss">
          <p>{title}</p>
        </div>
        <div className="w-full cflexss gap-[20px]">
          <div className="w-full flexbm">
            <form className="w-[308px] rounded-[6px] px-[12px] py-[8px] flexsm gap-[12px] border-[1px] shadow-sm">
              <img
                src="/search-icon.svg"
                alt="search-icon"
                className="w-[20px] h-[20px] cursor-pointer"
              />
              <input
                type="text"
                placeholder="Search articles"
                className="outline-none w-full"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
            <Button
              gap="8px"
              paddingX="12px"
              paddingY="6px"
              handleClick={() => {
                setContent("create article");
              }}
            >
              <Plus size="20px" />
              <p>New article</p>
            </Button>
          </div>
          <TableOfArticle
            articles={articles}
            setArticles={setArticles}
            setArticle={setArticle}
            setContent={setContent}
            searchQuery={searchQuery}
            setIsOpenModal={setIsOpenModal}
            setModalContent={setModalContent}
          />
        </div>
      </div>
    </>
  );
};

export default EachArticle;

const TableOfArticle = ({
  articles,
  setArticles,
  setArticle,
  setContent,
  searchQuery,
}) => {
  const MenuContent = [
    {
      name: "Edit article",
    },
    {
      name: "Move article",
    },
    {
      name: "View in help center",
    },
    {
      name: "Archive",
    },
  ];
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const PAGINATION = 5;
  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
    // Fetch and display data for the selected page
  };

  const onDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const articleReorder = async (newItems) => {
    let alpsToken = localStorage.getItem("alpsToken");
    let customized = [];
    for (let i = 0; i < newItems.length; i++) {
      customized.push({
        id: newItems[i].id,
        order: i + 1,
      });
    }
    // newItems.map((element, index) => {
    //   customized.push({
    //     id: element.id,
    //     order: index + 1,
    //   });
    // });
    await reorderArticle(alpsToken, { articles: customized });
  };

  const onDrop = (e, newIndex) => {
    const draggedIndex = e.dataTransfer.getData("index");
    const newItems = [...articles];
    const [removed] = newItems.splice(draggedIndex, 1);
    newItems.splice(newIndex, 0, removed);
    setArticles(newItems);
    articleReorder(newItems);
  };
  useEffect(() => {
    if (articles.length > 0) {
      setPageCount(Paginated(articles, PAGINATION));
    }
  }, [articles]);

  const filteredArticles = articles?.filter((article) =>
    article.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <>
      <table className="w-full p-0 m-0">
        <thead scope="column" className="text-[16px] text-[#4D4E51]">
          <tr className="w-full bg-[#F5F5F5] rounded-[6px]">
            <td className="px-[24px] py-[16px] flexsm font-[600] gap-[16px]">
              {/* <input
                type="checkbox"
                className="rounded-[4px] w-[24px] h-[24px] font-[600]"
              /> */}
              <p>Article name</p>
            </td>
            <td className="px-[24px] py-[16px] font-[600]">
              <p>Status</p>
            </td>
            <td className="px-[24px] py-[16px] font-[600]">
              <p>Views</p>
            </td>
            <td className="px-[24px] py-[16px] font-[600]">
              <p>Helpful</p>
            </td>
            <td className="px-[24px] py-[16px] font-[600]">
              <p>Not Helpful</p>
            </td>
          </tr>
        </thead>
        {articles.length > 0 && (
          <>
            <tbody className="w-full">
              {GetPaginatedData(currentPage, PAGINATION, filteredArticles).map(
                (article, index) => {
                  return (
                    <>
                      <tr
                        key={index}
                        draggable
                        onDragStart={(e) => onDragStart(e, index)}
                        onDragOver={onDragOver}
                        onDrop={(e) => onDrop(e, index)}
                        className="cursor-pointer border-b-[1px] bg-white hover:bg-[#F5F5F5]"
                        onClick={() => {
                          setArticle(article);
                          setContent("create article");
                        }}
                      >
                        <td className="px-[24px] flexsm gap-[16px] py-[16px]">
                          <img
                            src="/move1.svg"
                            alt="move-icon"
                            draggable={false}
                          />
                          <p>{article.title}</p>
                        </td>
                        <td className="px-[24px] py-[16px]">
                          <div className="flexsm gap-[8px] capitalize">
                            <div
                              className={`w-[8px] h-[8px] rounded-full ${
                                article.status === "published" && "bg-[#22C55E]"
                              } ${
                                article.status === "draft" && "bg-yellow-400"
                              } ${
                                article.status === "archived" && "bg-red-600"
                              }`}
                            />
                            <p className="capitalize">{article.status}</p>
                          </div>
                        </td>
                        <td className="px-[24px] font-[700] py-[16px]">
                          <p>{article.views}</p>
                        </td>
                        <td className="px-[24px] font-[700] py-[16px]">
                          <p>{article.upVoteCount}</p>
                        </td>
                        <td className="px-[24px] font-[700] py-[16px] flexbm gap-[16px]">
                          <p>{article.downVoteCount}</p>
                        </td>
                      </tr>
                    </>
                  );
                }
              )}
            </tbody>
          </>
        )}
      </table>
      {articles.length === 0 && (
        <div className="w-full">
          <SkeletonTheme baseColor="#f5f5f5" highlightColor="#cacecf">
            {new Array(6).fill().map((x, index) => (
              <Skeleton
                key={index}
                containerClassName="w-full h-[80px] rounded-[10px]"
                className="w-full h-[100vh]"
                width={"100%"}
                height={"45px"}
                borderRadius={"5px"}
              />
            ))}
          </SkeletonTheme>
        </div>
      )}
      <div className="w-full flexem mt-[10px]">
        <ReactPaginate
          previousLabel={<ArrowLeft size="20px" />}
          previousClassName={"p-[10px] border-[2px] rounded-l-[10px]"}
          nextClassName={"p-[10px] border-[2px] rounded-r-[10px]"}
          nextLabel={<ArrowRight size="20px" />}
          breakLabel={"..."}
          breakClassName={"w-full p-[10px]"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          pageClassName={"px-[20px] py-[10px] border-[2px]"}
          onPageChange={handlePageClick}
          containerClassName={"text-[14px] lg:text-[12px] flexem"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
};
