import React from "react";
import { ArrowRightOutline } from "heroicons-react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="mt-[130px] w-full flexmm px-[56px] md:px-[20px] mb-[40px] font-inter">
        <div className="w-full flex-shrink bg-[#131110] flexmm rounded-[20px] p-[70px] md:p-[30px]">
          <div className="w-[480px] cflexmm gap-[60px] px-[40px] md:px-[16px]">
            <div className="text-white font-bold text-[20px] flexmm gap-[8px] cursor-pointer">
              <div className="w-[85px] h-[85px] flexmm shadow-sm">
                <img
                  src="/alps-logo-full-blck.svg"
                  className="w-full h-full"
                  alt="logo"
                />
              </div>
            </div>
            <div className="cflexmm w-full gap-[20px]">
              <p className="text-[40px] md:text-[25px] font-medium text-white leading-[45px] text-center">
                Empowering Companies to Craft Helpful Guides.
              </p>
              <Link
                to="/register"
                className="flexmm text-white gap-[1px] text-[16px] md:text-[14px] font-medium"
              >
                <p>Try it for free</p>
                <ArrowRightOutline size="15px" />
              </Link>
            </div>
            <div className="cflexmm gap-[10px] text-[14px] font-normal text-[#FCFBFA]">
              <div className="flexmm gap-[15px]">
                <a href="https://x.com/tryalps" target="_blank" rel="noopener noreferrer">
              <img src="./twitter.svg" alt="Twitter" />
                </a>
                <a href="https://www.linkedin.com/company/alps-systems" target="_blank" rel="noopener noreferrer">
                  <img src="./linkedIn.svg" alt="LinkedIn" />
                </a>
              </div>
              <Link to="/privacy-policy">
              <p>Privacy Policy</p>
              </Link>
            </div>
            <p className="text-[14px] font-normal text-secondary4">
              © 2024 Alps Systems Limited.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
