import React from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { Plus } from "heroicons-react";

const Body = () => {
  return (
    <>
      <div className="w-full mt-[120px] px-[56px] md:px-[20px] py-[20px] cflexmm font-inter">
        {/* <div className="w-full px-[20px] md:px-0 gap-[100px] flexmm md:flex-wrap">
          <div className="cflexss w-[540px] md:w-full gap-[10px] text-[#4D4E51] flex-shrink">
            <div className="rounded-[11px] w-full h-[350px] border-[1px] border-black"></div>
            <p className="text-[26px] font-semibold">Collections & Articles</p>
            <p className="text-[20px] font-medium">
              Create comprehensive guides, tutorials, and documentation that
              provides your users with the knowledge they need to succeed.
            </p>
          </div>

          <div className="cflexss w-[540px] md:w-full gap-[10px] text-[#4D4E51] flex-shrink">
            <div className="rounded-[11px] w-full h-[350px] border-[1px] border-black"></div>
            <p className="text-[26px] font-semibold">Guides & Videos</p>
            <p className="text-[20px] font-medium">
              Create comprehensive guides, tutorials, and documentation that
              provides your users with the knowledge they need to succeed.
            </p>
          </div>
        </div> */}

        <div className="mt-[100px] md:mt-[70px] w-full cflexmm gap-[30px]">
          <p className="text-[11px] font-bold uppercase">
            Trusted by world-leading brands
          </p>
          <div className="cflexmm gap-[10ox]">
            <Link to="/register">
              <Button handleClick={() => {}}>
                <Plus size="20px" />
                <p>Add your company name here</p>
              </Button>
            </Link>
            <p className="text-[12px] font-[500] py-[10px] text-[#4D4E51]">
              We’ll unveil all our wonderful customers at once
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
