export const markdownToJson = (markdown) => {
  const lines = markdown.split("\n");
  const blocks = [];
  let currentList = null;
  let currentChecklist = null;
  let i = 0;

  const generateId = () => "_" + Math.random().toString(36).substr(2, 9);

  const parseListItems = (lines, isOrdered = false, level = 0) => {
    const items = [];
    while (i < lines.length) {
      const line = lines[i];
      const indent = "  ".repeat(level);
      const regex = isOrdered
        ? new RegExp(`^${indent}\\d+\\. `)
        : new RegExp(`^${indent}- `);

      if (line.match(regex)) {
        const content = line.replace(regex, "").trim();
        i++;
        const subItems = parseListItems(lines, isOrdered, level + 1);
        items.push({ content, items: subItems });
      } else if (line.startsWith(indent + " ") || line === "") {
        i++;
      } else {
        break;
      }
    }
    return items;
  };

  while (i < lines.length) {
    const line = lines[i];
    if (line.trim() === "---") {
      blocks.push({
        id: generateId(),
        type: "delimiter",
        data: {},
      });
    } else if (line.startsWith("###### ")) {
      blocks.push({
        id: generateId(),
        type: "header",
        data: {
          text: line.replace("###### ", ""),
          level: 6,
        },
      });
    } else if (line.startsWith("##### ")) {
      blocks.push({
        id: generateId(),
        type: "header",
        data: {
          text: line.replace("##### ", ""),
          level: 5,
        },
      });
    } else if (line.startsWith("#### ")) {
      blocks.push({
        id: generateId(),
        type: "header",
        data: {
          text: line.replace("#### ", ""),
          level: 4,
        },
      });
    } else if (line.startsWith("### ")) {
      blocks.push({
        id: generateId(),
        type: "header",
        data: {
          text: line.replace("### ", ""),
          level: 3,
        },
      });
    } else if (line.startsWith("## ")) {
      blocks.push({
        id: generateId(),
        type: "header",
        data: {
          text: line.replace("## ", ""),
          level: 2,
        },
      });
    } else if (line.startsWith("# ")) {
      blocks.push({
        id: generateId(),
        type: "header",
        data: {
          text: line.replace("# ", ""),
          level: 1,
        },
      });
    } else if (line.startsWith("> ")) {
      const [text, caption] = line
        .split("\n")
        .map((str) => str.replace("> ", ""));
      blocks.push({
        id: generateId(),
        type: "quote",
        data: {
          text: text.trim(),
          caption: caption.trim(),
          alignment: "left",
        },
      });
    } else if (line.startsWith("- [ ] ") || line.startsWith("- [x] ")) {
      if (!currentChecklist) {
        currentChecklist = {
          id: generateId(),
          type: "checklist",
          data: {
            items: [],
          },
        };
        blocks.push(currentChecklist);
      }
      currentChecklist.data.items.push({
        text: line.replace("- [ ] ", "").replace("- [x] ", ""),
        checked: line.startsWith("- [x] "),
      });
    } else if (line.startsWith("- ")) {
      if (!currentList) {
        currentList = {
          id: generateId(),
          type: "list",
          data: {
            style: "unordered",
            items: [],
          },
        };
        blocks.push(currentList);
      }
      currentList.data.items = parseListItems(lines, false);
    } else if (/^\d+\. /.test(line)) {
      if (!currentList) {
        currentList = {
          id: generateId(),
          type: "list",
          data: {
            style: "ordered",
            items: [],
          },
        };
        blocks.push(currentList);
      }
      currentList.data.items = parseListItems(lines, true);
    } else if (line.match(/^!\[.*?\]\(.*?\)$/)) {
      const match = line.match(/^!\[(.*?)\]\((.*?)(?:"(.*?)")?\)$/);
      blocks.push({
        id: generateId(),
        type: "image",
        data: {
          file: {
            url: match[2].trim(),
            borderRadius: "0px", // default value
            width: "auto", // default value
            height: "auto", // default value
          },
          caption: match[1].trim(),
          withBorder: false,
          stretched: false,
          withBackground: false,
        },
      });
    } else if (line.trim().startsWith("<div>")) {
      blocks.push({
        id: generateId(),
        type: "raw",
        data: {
          html: line.trim(),
        },
      });
    } else if (line.startsWith("```")) {
      const codeLines = [];
      i++; // Skip the initial ```
      while (lines[i] !== "```" && i < lines.length) {
        codeLines.push(lines[i]);
        i++;
      }
      blocks.push({
        id: generateId(),
        type: "code",
        data: {
          code: codeLines.join("\n"),
        },
      });
    } else if (line.trim() !== "") {
      blocks.push({
        id: generateId(),
        type: "paragraph",
        data: {
          text: line.trim(),
        },
      });
    } else {
      currentList = null;
      currentChecklist = null;
    }
    i++;
  }

  return {
    time: Date.now(),
    blocks: blocks,
    version: "2.29.1",
  };
};

export const jsonToMarkdown = (json) => {
  const processListItems = (items, isOrdered = false, level = 0) => {
    return items
      .map((item, index) => {
        const prefix = isOrdered ? `${index + 1}.` : "-";
        const indent = "  ".repeat(level);
        const content = `${indent}${prefix} ${item.content}`;
        const subItems =
          item.items.length > 0
            ? processListItems(item.items, isOrdered, level + 1)
            : "";
        return `${content}\n${subItems}`;
      })
      .join("\n");
  };

  return json.blocks
    .map((block) => {
      switch (block.type) {
        case "paragraph":
          return block.data.text;
        case "header":
          return `${"#".repeat(block.data.level)} ${block.data.text}`;
        case "checklist":
          return block.data.items
            .map((item) => `- [${item.checked ? "x" : " "}] ${item.text}`)
            .join("\n");
        case "list":
          if (block.data.style === "unordered") {
            return processListItems(block.data.items, false);
          } else {
            return processListItems(block.data.items, true);
          }
        case "quote":
          return `> ${block.data.text}\n> ${block.data.caption}`;
        case "table":
          return block.data.content
            .map((row) => `| ${row.join(" | ")} |`)
            .join("\n");
        case "delimiter":
          return "---";
        case "raw":
          return block.data.html;
        case "code":
          return `\`\`\`\n${block.data.code}\n\`\`\``;
        case "image":
          return `![${block.data.caption}](${block.data.file.url})`;
        default:
          return "";
      }
    })
    .join("\n\n");
};
