import React, { useState } from "react";
import { Link } from "react-router-dom";
import { userLogin, googleAuthentication } from "../services/request";
import Loader from "../components/Loader/Loader";
import Loader2 from "../components/Loader/Loader2";
import { EyeOutline, EyeOffOutline } from "heroicons-react";
import TermsPrivacy from "./collections/TermsPrivacy";
// import { data } from "autoprefixer";

const Login = () => {
  const [loginDetails, setLoginDetails] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [gLoading, setGLoading] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [showP, setShowP] = useState(false);
  const [modal, setModal] = useState(null);
  const [nextStep, setNextStep] = useState(false);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setLoginDetails({ ...loginDetails, [name]: value });
  };

  const handeSubmit = async (e) => {
    e.preventDefault();
    if (loginDetails.email) {
      setNextStep(true);
    }
    if (loginDetails.email && loginDetails.password) {
      setLoading(true);
      await userLogin(loginDetails, setPopUp, setModal);
      setLoading(false);
    }
  };

  const handleGoogleAuthentication = async () => {    
    setGLoading(true);
    window.location.href = "https://api.tryalps.com/api/v1/google-auth"
    // redirects to google authentication page
    // await googleAuthentication(setPopUp, setModal);
    // setGLoading(false);
  };
  return (
    <>
      {popUp && modal}
      <div className="w-full h-[100vh] cflexbm text-[16px] pt-[32px] pb-[50px] font-inter">
        <Link to="/" className="w-[85px] h-[85px] flexmm shadow-sm">
          <img src="/logoAlps.svg" className="w-full h-full" alt="logo" />
        </Link>
        <form
          className="m-[10px] p-[50px] w-[400px] cflexsm flex-shrink pt-[64px] pb-[70px]"
          onSubmit={handeSubmit}
        >
          <p className="text-[29.63px] font-[700] leading-[48px] pb-[24px] text-[#2D333A]">
            Welcome back
          </p>
          <input
            type="email"
            name="email"
            value={loginDetails.email}
            onChange={handleChange}
            placeholder="Email address"
            className=" border-[2px] shadow-sm w-full p-[10px] mb-[15px] rounded-[8px] outline-none"
          />
          {nextStep && (
            <>
              <div className="w-full cflexms ">
                <div className="border-[2px] flexmm gap-[10px] shadow-sm w-full p-[10px] mb-[15px] rounded-[8px]">
                  <input
                    type={showP ? "text" : "password"}
                    name="password"
                    value={loginDetails.password}
                    onChange={handleChange}
                    placeholder="Password"
                    className="border-none w-full outline-none"
                  />
                  {showP ? (
                    <EyeOffOutline
                      size="20px"
                      onClick={() => setShowP(!showP)}
                      className="cursor-pointer"
                    />
                  ) : (
                    <EyeOutline
                      size="20px"
                      onClick={() => setShowP(!showP)}
                      className="cursor-pointer"
                    />
                  )}
                </div>
                <Link
                  to="/forgot"
                  className="text-[14px] pb-[15px] cursor-pointer"
                >
                  Forgot password?
                </Link>
              </div>
            </>
          )}
          <button
            type="submit"
            className="text-[16px] mb-[24px] text-white font-[600] border-none px-[16px] py-[8px] flexmm rounded-[8px] w-full bg-buttonColor"
          >
            {loading ? <Loader /> : <p className="leading-[22px]">Continue</p>}
          </button>
          <div className="relative pb-[30px] border-b-[2px] mb-[24px] w-full flexmm">
            <p>
              Don't have an account?{" "}
              <Link to={"/register"} className="underline">
                {" "}
                Sign up
              </Link>
            </p>
            <p className="absolute text-center bottom-[-15px] text-[#707070] bg-white p-[5px]">
              OR
            </p>
          </div>
          <div
            className="w-full rounded-[4px] border-[2px] flexmm gap-[12px] px-[16px] py-[12px] cursor-pointer"
            onClick={() => {
              handleGoogleAuthentication();
            }}
          >
            {gLoading && <Loader2 />}
            <img
              src="/google-icn.svg"
              alt="google-icon"
              className="w-[24px] h-[24px]"
            />
            <p>Continue with Google</p>
          </div>
        </form>
        <TermsPrivacy />
      </div>
    </>
  );
};

export default Login;
