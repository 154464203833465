import React, { useState, useEffect } from "react";
import Button from "./Button";
import { X } from "heroicons-react";
import {
  getUnlinkedArticles,
  linkMultipleArticles,  
} from "../services/request";
import Loader from "./Loader/Loader";
import Loader2 from "./Loader/Loader2";

const ConnectArticle = ({
  setIsOpenModal,
  categoryDetails,
  categoryId,
  setType,
  createCategoryRequest,
  setRefresh,
  refresh,
}) => {
  const [articles, setArticles] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [linking, setLinking] = useState(false);

  const getArticles = async (userId, alpsToken) => {
    setLoading(true);
    let data = await getUnlinkedArticles(alpsToken);
    setArticles(data);
    setLoading(false);
  };

  const linkArticles = async () => {
    let alpsToken = localStorage.getItem("alpsToken");
    setLinking(true);
    if (categoryId) {
      setType("update");
    }
    await createCategoryRequest();
    const catId = localStorage.getItem("alpsCategoryId");
    await linkMultipleArticles({ articles: selected }, catId, alpsToken);
    setLinking(false);
    setRefresh(!refresh);
    setIsOpenModal(false);
  };

  useEffect(() => {
    let alpsToken = localStorage.getItem("alpsToken");
    let userData = localStorage.getItem("userData");
    let userJson;
    if (userData) {
      userJson = JSON.parse(userData);
      getArticles(userJson.id, alpsToken);
    }
  }, []);
  return (
    <>
      <div
        className="fixed z-[9999] top-0 right-0 w-full h-[100vh] flexmm bg-secondary3 cursor-default font-inter"
        onClick={() => {
          setIsOpenModal(false);
        }}
      >
        <div
          className={`flexms w-[483px] flex-shrink rounded-[6px] h-auto p-[20px] bg-white shadow-xl`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="cflexss w-full h-full gap-[32px]">
            <div className="w-full flexbm text-[18px] font-semibold text-buttonColor">
              <p>
                Link an article to{" "}
                <span className="text-[#7C7D7E]">
                  {categoryDetails["name"]}
                </span>
              </p>
              <X
                size="20px"
                color="#7C7D7E"
                className="cursor-pointer"
                onClick={() => {
                  setIsOpenModal(false);
                }}
              />
            </div>
            <div className="cflexss w-full h-[70%] gap-[8px]">
              <p className="text-[14px] text-[#656667] font-semibold">
                Select article
              </p>
              <div className="w-full cflexss h-full overflow-y-auto">
                {loading ? (
                  <>
                    <div className="w-full h-full cflexmm gap-[20px]">
                      <p>Fetching Articles...</p>
                      <Loader2 />
                    </div>
                  </>
                ) : (
                  <>
                    {articles?.length > 0 ? (
                      <>
                        {articles?.map((article, index) => {
                          return (
                            <div
                              key={index}
                              className="w-full text-[16px] font-normal flexbm py-[16px] border-b-[2px] cursor-pointer"
                              onClick={() => {
                                if (
                                  selected.find((item) => item === article.id)
                                ) {
                                  setSelected(
                                    selected.filter(
                                      (item) => item !== article.id
                                    )
                                  );
                                } else {
                                  setSelected([...selected, article.id]);
                                }
                              }}
                            >
                              <div className="flexsm gap-[16px]">
                                <input
                                  type="checkbox"
                                  checked={
                                    selected.find((item) => item === article.id)
                                      ? true
                                      : false
                                  }
                                />
                                <p>{article.title}</p>
                              </div>
                              <div className="flexmm gap-[8px]">
                                <div
                                  className={`w-[8px] h-[8px] rounded-full  ${
                                    article.status === "published" &&
                                    "bg-[#22C55E]"
                                  } ${
                                    article.status === "draft" &&
                                    "bg-yellow-400"
                                  } ${
                                    article.status === "archived" &&
                                    "bg-red-600"
                                  } capitalize`}
                                />
                                <p className="capitalize">{article.status}</p>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <div className="w-full h-full flexmm">
                          <p>No unlinked articles are available!</p>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="w-full flexem">
              <Button
                handleClick={() => {
                  if (selected) {
                    linkArticles();
                  }
                }}
                width={"300px"}
              >
                {linking ? <Loader /> : <p>Add article</p>}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectArticle;
