import React, { useState } from "react";
import { ChevronRight, ChevronDown } from "heroicons-react";
import { SketchPicker } from "react-color";

const Colour = ({ view, setView, designInfo, setDesignInfo }) => {
  const [viewColor, setViewColor] = useState(false);
  const [showB, setShowB] = useState(false);
  const [showT, setShowT] = useState(false);
  return (
    <>
      {viewColor ? (
        <>
          <div
            className="w-[85%] flex-shrink bg-[#F5F5F5] border-[2px] rounded-[8px] cflexss"
            onClick={() => {
              setShowB(false);
              setShowT(false);
            }}
          >
            <div
              className={`w-full flexbm px-[14px] py-[12px] rounded-t-[8px] cursor-pointer`}
              onClick={(e) => {
                e.stopPropagation();
                setShowB(false);
                setShowT(false);
                setViewColor(false);
              }}
            >
              <p>Colours</p>
              <ChevronDown size="24px" />
            </div>
            <div className="w-full bg-white cflexss rounded-b-[8px] text-[#656667] font-normal text-[14px]">
              <div className="relative w-full flexbm p-[16px] pt-[20px] border-b-[1px] gap-[16px]">
                {showB && (
                  <div
                    className="absolute top-0 left-[50px] z-[50]"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <SketchPicker
                      color={designInfo["brandColour"]}
                      onChange={(newColor) => {
                        setDesignInfo({
                          ...designInfo,
                          brandColour: newColor.hex,
                        });                        
                      }}
                    />
                  </div>
                )}
                <div className="cflexss gap-[4px]">
                  <p className="font-semibold text-[#36383A]">Brand colour</p>
                  <p>
                    Brand colour for major navigational elements and buttons
                  </p>
                </div>
                <div
                  className="rounded-[6px] p-[8px] flexmm gap-[8px] border-[1px] cursor-pointer"
                  onClick={(e) => {
                    setShowB(true);
                    setShowT(false);
                    e.stopPropagation();
                  }}
                >
                  <div
                    className="rounded-[6px] w-[24px] h-[24px]"
                    style={{ background: designInfo.brandColour }}
                  />
                  <ChevronDown size="20px" />
                </div>
              </div>

              <div className="relative w-full flexbm p-[16px] pt-[20px] gap-[16px]">
                {showT && (
                  <div
                    className="absolute top-0 left-[50px] z-[50]"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <SketchPicker
                      color={designInfo.textColour}
                      onChange={(newColor) => {
                        setDesignInfo({
                          ...designInfo,
                          textColour: newColor.hex,
                        });                        
                      }}
                    />
                  </div>
                )}
                <div className="cflexss gap-[4px]">
                  <p className="font-semibold text-[#36383A]">Text colour</p>
                  <p>Text colour for body and heading elements</p>
                </div>
                <div
                  className="rounded-[6px] p-[8px] flexmm gap-[8px] border-[1px] cursor-pointer"
                  onClick={(e) => {
                    setShowT(true);
                    setShowB(false);
                    e.stopPropagation();
                  }}
                >
                  <div
                    className="rounded-[6px] w-[24px] h-[24px]"
                    style={{ background: designInfo.textColour }}
                  />
                  <ChevronDown size="20px" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`w-[85%] flexbm flex-shrink ${
              view === "Colours" && "bg-[#F5F5F5]"
            } px-[14px] py-[12px] rounded-[8px] cursor-pointer border-[2px]`}
            onClick={() => {
              setViewColor(true);
              setView("Colours");
            }}
          >
            <p>Colours</p>
            <ChevronRight size="24px" />
          </div>
        </>
      )}
    </>
  );
};

export default Colour;
