import React, { useState } from "react";
import Button from "./Button";
import FileBase64 from "react-file-base64";

const ChangeCover = ({
  setIsOpenModal,
  setCategoryDetails,
  categoryDetails,
  setChanging,
  changing,   
  // setCoverUpdated
}) => {
  const [view, setView] = useState("upload");
  const [link, setLink] = useState("");
  const OnChangeUploadFile = async (image, type) => {
    if (type === "file") {
      if (
        image.type === "image/png" ||
        image.type === "image/jpg" ||
        image.type === "image/jpeg" ||
        image.type === "image/jfif"
      ) {
        // setCoverUpdated(true)
        setCategoryDetails({ ...categoryDetails, file: image });
        setChanging(!changing);        
      }
    } else if (type === "link" && link) {
      // setCoverUpdated(true)
      setCategoryDetails({ ...categoryDetails, file: image });
      setChanging(!changing);      
    }    
    setIsOpenModal(false);
  };
  return (
    <>
      <div className="absolute z-10 ml-[-195px] mt-[30px] flexmm font-inter">
        <div
          className="flexmm w-[390px] flex-shrink rounded-[6px] p-[16px] bg-white shadow-xl"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="cflexss w-full gap-[32px]">
            <div className="w-full flexbm border-b-[2px] text-[14px] font-[500]">
              <div className="flexss gap-[8px]">
                <div
                  className={`cflexmm pb-[5px] ${
                    view === "upload" && "border-[#1F2023] border-b-[2px]"
                  } cursor-pointer transition-all`}
                  onClick={() => {
                    setView("upload");
                  }}
                >
                  <span
                    className={`rounded-[10px] px-[12px] py-[6px] ${
                      view === "upload" && "bg-[#F5F5F5]"
                    } transition-all`}
                  >
                    Upload
                  </span>
                </div>
                <div
                  className={`cflexmm pb-[5px] ${
                    view === "link" && "border-[#1F2023] border-b-[2px]"
                  } cursor-pointer transition-all`}
                  onClick={() => {
                    setView("link");
                  }}
                >
                  <span
                    className={`rounded-[10px] px-[12px] py-[6px] ${
                      view === "link" && "bg-[#F5F5F5]"
                    } transition-all`}
                  >
                    Link
                  </span>
                </div>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setCategoryDetails({ ...categoryDetails, file: "" });
                  setIsOpenModal(false);
                }}
              >
                <p>Remove</p>
              </div>
            </div>
            <div className="cflexmm w-full gap-[16px]">
              {view === "upload" && (
                <>
                  <Button width="280px" handleClick={() => {}}>
                    <div className="relative">
                      <p>Upload file</p>
                      <div className="absolute top-0 left-[-60px] opacity-0">
                        <FileBase64
                          name="cover"
                          defaultValue={categoryDetails["cover"]}
                          multiple={false}
                          onDone={(base64) => {
                            OnChangeUploadFile(base64, "file");
                          }}
                        />
                      </div>
                    </div>
                  </Button>
                  <div className="cflexmm text-[16px]">
                    <p>Upload a file or drag and drop</p>
                    <p className="text-[#7C7D7E]">PNG, JPG, GIF upto 5MB</p>
                  </div>
                </>
              )}
              {view === "link" && (
                <>
                  <input
                    type="url"
                    value={link}
                    name="link"
                    onChange={(e) => {
                      setLink(e.target.value);
                    }}
                    className="w-full border-[2px] px-[12px] py-[8px] rounded-[6px] outline-none"
                    placeholder="Paste image link..."
                  />
                  <Button
                    width="280px"
                    handleClick={() => {
                      OnChangeUploadFile(link, "link");
                    }}
                  >
                    <p>Submit</p>
                  </Button>
                  <div className="cflexmm text-[16px]">
                    <p className="text-[#7C7D7E]">
                      works with any image from the web
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeCover;
