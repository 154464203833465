import React from "react";

const Button = ({
  children,
  gap,
  width,
  shadow = false,
  type = "button",
  text = "14px",
  paddingY = "8px",
  paddingX = "16px",
  rounded = "10px",
  handleClick,
}) => {
  return (
    <>
      <button
        onClick={() => {
          handleClick();
        }}
        type={type}
        className={`bg-buttonColor hover:bg-buttonHover text-white rounded-[${rounded}] font-inter w-[${width}] gap-[${gap}] px-[${paddingX}] py-[${paddingY}] flexmm font-[500] text-[${text}] ${
          shadow && "shadow-md"
        } outline-none`}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
