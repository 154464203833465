import React, { useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import { setBrand } from "../services/request";
import TermsPrivacy from "./collections/TermsPrivacy";

const AddBrand = () => {  
  const [name, setName] = useState("");
  const [changing, setChanging] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    const alpsToken = localStorage.getItem("alpsToken");
    const redirect = localStorage.getItem("addBrandRedirect");
    e.preventDefault();
    if (name) {
      setLoading(true);
      await setBrand(
        alpsToken,
        { name },
        redirect ? "addBrandRedirect" : "completed"
      );
      setLoading(false);
    }
  };
  return (
    <>
      <div className="w-full h-[100vh] cflexbm text-[16px] pt-[32px] pb-[50px] font-inter">
        <Link to="/" className="w-[85px] h-[85px] flexmm shadow-sm">
          <img src="/logoAlps.svg" className="w-full h-full" alt="logo" />
        </Link>
        <form
          className="m-[10px] p-[50px] w-[400px] cflexsm flex-shrink pt-[64px] pb-[70px]"
          onSubmit={handleSubmit}
        >
          <p className="text-[24.63px] font-[700] leading-[48px] pb-[24px] text-[#2D333A]">
            Lastly, own your brand
          </p>
          <div className="w-full cflexss mb-[13px] gap-[5px]">
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setChanging(!changing);
              }}
              placeholder="Workspace name"
              className=" border-[2px] shadow-sm w-full p-[10px] rounded-[8px] outline-none"
            />
            <div className="flexmm gap-[6px] text-[11px]">
              <span className="text-gray-500">{`${name}.tryalps.app`}</span>{" "}
              <p className="font-medium">You can change this later</p>
            </div>
          </div>{" "}
          <button
            type="submit"
            className="text-[16px] mb-[24px] text-white font-[600] border-none px-[16px] py-[8px] flexmm rounded-[8px] w-full bg-buttonColor"
          >
            {loading ? <Loader /> : <p className="leading-[22px]">Continue</p>}
          </button>
        </form>
        <TermsPrivacy />
      </div>
    </>
  );
};

export default AddBrand;
